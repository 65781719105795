import { useEffect, useState } from "react";
import {
    Container,
    Header,
    OrdersWrapper,
    Order,
    IconWrapper,
    // Icon,
    InfoOrder,
    Balance,
    OrderDetails,
    BalanceDetails,
    Type,
    Name,
    // ArrowIcon
} from "./styles";

import { BsArrowUpShort, BsArrowDownShort } from 'react-icons/bs'

import { api } from "../../services/api";
// import { startOfMonth, endOfMonth } from "date-fns";
import { normalizeName } from "../../utils/normelizaName";
import { currencyFormat } from "../../utils/currencyFormat";
import { format, startOfYear, endOfYear } from "date-fns";
import brasilLocale from 'date-fns/locale/pt-BR';

import { Link } from "react-router-dom";
import { Skeleton } from "../skeleton";
import { service } from "../../services/vf";

interface IItems {
  id: string;
  description: string;
  balanceAfter: number;
  date: string;
  status: string;
  // type: string;
  credit: boolean;
  // amount: number;
  //

  category: string;
  amount: string;
  type: string;
  createdAt: string;
  transactionData: any;
}

export const Orders = () => {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState<IItems[]>([]);
    useEffect(() => {
      // const start = startOfYear(new Date());
      // const end = endOfYear(new Date());

       service.get(`/accounts/extracts?page=1`).then(({data}) => {
        setItems(data.rows.slice(0, 5));
        setLoading(false);
      });

    }, []);

    function getType(type: string): string {
      if (type === 'credit') {
        return "Crédito";
      }

      if (type === 'debit') {
        return "Débito";
      }

      // if (type === 'P2P') {
      //   return "Transferência";
      // }

      // if (type === 'BILLET') {
      //   return "Boleto";
      // }

      // if (type === 'PAYMENT') {
      //   return "Pagamento";
      // }

      // if (type === 'INITIAL') {
      //   return '';
      // }

      return type;
    }

    function getDescription(transaction: IItems) {
      let description = '';
      let category = transaction.category;
      if (category === 'SLIPPAYMENT') {
        category = 'Pagamento'
      }
  
      // saida
      if (transaction.type === 'debit') {
        if (transaction.category === 'FEE') {
          description = `Tarifa`;
        } else {
          description = `${category} para ${transaction.transactionData?.pixResponse?.owner?.name
            || transaction?.transactionData?.clientName
            || transaction.transactionData?.keyTarget
            || transaction.transactionData?.clientFantasyName
            || transaction.transactionData?.clientCompanyName
            || transaction.transactionData?.receiverInformations?.name
            || transaction.transactionData?.recept_name}`
        }
      }
  
      // entrada
      if (transaction.type === 'credit') {
        if (transaction.category === 'FEE') {
          description = `Tarifa`;
        } else if (transaction.category === 'FAIL') {
          description = `Estorno de transferência ${transaction.transactionData.clientName}`
        } else if (transaction.category === "TEDREVERSAL") {
          description = `Estorno de ${transaction.transactionData?.clientNamePayer || transaction.transactionData?.clientName}`
        }
        else {
          description = `${transaction.category} recebido de ${transaction.transactionData?.clientNamePayer || transaction.transactionData?.clientName}`
        }
      }
  
      return description;
    }

    return (
        <Container>
            <Header>
                <span>Últimos lançamentos</span>
                <Link to="/extract">
                  <p>Ver extrato completo</p>
                </Link>

            </Header>
            <OrdersWrapper>

            {!loading && !items.length && (
              <div>
                <div className="text-center">
                  <h3 className="mt-2 text-sm font-medium text-gray-900">Nenhum lançamento para o mês de <strong>{format(new Date(), 'LLLL', { locale: brasilLocale })}</strong></h3>
                  <p className="mt-1 text-sm text-gray-500">Faça sua primeira transação</p>
                  <div className="mt-6">
                    <button
                      type="button"
                      className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Transferir
                    </button>
                  </div>
                </div>
              </div>
            )}

          <Skeleton isActive={loading} repeat={4} />


            {!loading && items.map(item => (
              <Order key={item.id}>
                  <OrderDetails>
                      <IconWrapper>
                          {/* <Icon src={FoodIconSVG} /> */}
                          {item.credit ? (
                            <BsArrowUpShort color="var(--green)" size={21} />
                          ) : (
                            <BsArrowDownShort color="var(--boxText)" size={21} />
                          )}
                      </IconWrapper>
                      <InfoOrder>
                          <Type>{getType(item.type)}</Type>
                          <Name className="truncate">{normalizeName(getDescription(item))}</Name>
                      </InfoOrder>
                  </OrderDetails>
                  <BalanceDetails>
                      <Balance credit={item.credit}>
                        {currencyFormat(Number(item.amount) / 100)}
                      </Balance>
                      {/* <ArrowIcon credit={item.credit.toString()} /> */}
                  </BalanceDetails>
              </Order>
            ))}
            </OrdersWrapper>
        </Container>
    )
}
