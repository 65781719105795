import React, { FormEvent, useEffect, useState } from "react";
import {
  Container,
  ContentWrapper,
  GoBackButtonWrapper,
  Text,
  Title,
  WrapperContent,
  WrapperLeft,
  WrapperRight,
  WrapperButton,
  WrapperForm,
  Button,
  ResendButton,
} from "./styles";

import { ReactComponent as ArrowLeft } from "../../assets/LParrowLeft.svg";
import FormButton from "../../components/LPFormButton";
import { useLocation, useNavigate } from "react-router-dom";
import { Loading } from "../../components/loading";

import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { api, parseError } from "../../services/api";
import { ExclamationIcon } from "@heroicons/react/solid";
import { addDays, addHours, format } from "date-fns";
import { useClient } from "../../hooks/client";
import axios from "axios";
import { useNotification } from "../../hooks/notification";
// import { cpf, cnpj } from 'cpf-cnpj-validator';

import { service , serviceError } from "../../services/vf";
import { useAuth } from "../../hooks/auth";

type FormDataPF = {
  name: string;
  document: string;
  email: string;
  celular: string;
  birthDate: string;
  password: string;
  passwordRepeat: string;
};

type FormDataStep2PF = {
  token: string;
};

type FormDataStep3PF = {
  cnpj: string;
  companyName: string;
  tradingName: string;
  dateStartCompany: string;
  uf: string;
  mother: string;
  father: string;
}

type FormDataPartners = {
  name: string;
  cpf: string;
  birthdate: string;
  phone: string;
  mothersName: string;
}

type IAccount = {
  name: string;
  document: string;
  birthDate: string;
  celular: string;
  email: string;
  value?: number;
  password?: string;
  partner?: {
    name: string;
    document: string;
    birthDate: string;
  }
}

const schemaPF = yup.object({
  name: yup.string().required("Nome é obrigatório"),
  document: yup.string().required("CNPJ é obrigatório"),
  email: yup.string().email().required('Email é obrigatório'),
  celular: yup.string().required('Celular é obrigatório'),
  birthDate: yup.string().required('Data de fundação é obrigatório'),
  password: yup.string()
  .required('Senha é obrigatorio').matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
    "Deve conter 8 caracteres, uma maiúscula, uma minúscula, um número e um caractere especial."
  ),
  passwordRepeat: yup.string()
  .required('Senha é obrigatorio').matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
    "Deve conter 8 caracteres, uma maiúscula, uma minúscula, um número e um caractere especial."
  )
}).required();

const schemaStep2PF = yup.object({
  token: yup.string().required('Token é obrigatorio'),
}).required();

const schemaStep3PJ = yup.object({
  companyName: yup.string().required('Emissor é obrigatorio'),
  cnpj: yup.string().required("RG é obrigatório"),
  dateStartCompany: yup.string().required('Data da emissão é obrigatorio'),
  uf: yup.string().required('UF é obrigatorio'),
  mother: yup.string().required('Nome da Mãe é obrigatorio'),
  father: yup.string().required('Nome do Pai é obrigatorio'),
}).required();

const schemaPartners = yup.object({
  name: yup.string().required('Nome é obrigatorio'),
  cpf: yup.string().required('CPF fantasia é obrigatorio'),
  birthdate: yup.string().required('Data de nascimento é obrigatorio'),
  phone: yup.string().required('Celular é obrigatorio'),
  mothersName: yup.string().required('Nome da mãe é obrigatorio'),
}).required();


interface CompanieProps {
  status: string | null;
}

export default function Individuals ({ status }: CompanieProps) {
  const { signOut } = useAuth()
  const location = useLocation();
  const { client } = useClient();
  const [loading, setLoading] = useState(false);
  const [loadingResend, setLoadingResend] = useState(false);
  const [error, setError] = useState('');
  const { setOpenNotification } = useNotification();

  const [type, setType] = useState(0);


  const [account, setAccount] = useState<IAccount>({} as IAccount);

  const [value, setValue] = useState(0);
  const [,setMaskedValue] = useState('');
  const [cep, setCep] = useState('');
  const [street, setStreet] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [number, setNumber] = useState('');
  const [complement, setComplement] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');

  const [typeDoc, setTypeDoc] = useState('SELFIE');

  const [step, setStep] = useState(() => {
    const statusStorage = status || localStorage.getItem('@stricv2:status');

    if (statusStorage) {
      if (statusStorage === 'WAITING_TOKEN') {
        return 2;
      }

      if (statusStorage === 'WAITING_FLOW') {
        return 3;
      }

      if (statusStorage === 'WAITING_PARTNERS') {
        return 3;
      }

      if (statusStorage === 'WAITING_ADDRESS') {
        return 5;
      }

      if (statusStorage === 'WAITING_DOCUMENTS') {
        return 7;
      }

      if (statusStorage === 'WAITING_VALIDATION') {
        return 99;
      }

      if (statusStorage === 'WAITING_CHANGES') {
        return 3;
      }

      if (statusStorage === 'UNAPPROVED') {
        return 50;
      }

      // if (statusStorage === 'WAITING_VALIDATION') {
      //   return 99;
      // }
    }

    return 1;
  });

  const [sucess, setSuccess] = useState(() => {
    const statusStorage = localStorage.getItem('@stricv2:status');

    if (statusStorage === 'WAITING_ANALYSIS' || statusStorage === 'UNDER_ANALYSIS') {
      return true;
    }

    return false;
  });

  const [token, setToken] = useState(() => {
    const tokenStorage = localStorage.getItem('@stricv2:id');
    if (tokenStorage) {
      return tokenStorage;
    }
    return '';
  });


  const navigation = useNavigate();
  // const { setOpenNotification } = useNotification();
  const handleGoBack = async () => {
    await signOut();
    navigation("/signin");
  };

  useEffect(() => {
    if (!location.search || location.search === null || location.search === '') {
      navigation('/signin');
    }

    if (location.search === '?type=pf') {
      setType(1);
    } else {
      setType(2);
    }
  }, [location.search, navigation]);

  const { register, handleSubmit, formState: { errors } } = useForm<FormDataPF>({
    resolver: yupResolver(schemaPF)
  });

  const { register: registerStep2PF, handleSubmit: handleSubmitStep2PF, formState: { errors: errorsStep2PF } } = useForm<FormDataStep2PF>({
    resolver: yupResolver(schemaStep2PF)
  });

  const { register: registerStep3PJ, handleSubmit: handleSubmitStep3PJ, formState: { errors: errorsStep3PJ } } = useForm<FormDataStep3PF>({
    resolver: yupResolver(schemaStep3PJ)
  });

  const { register: registerPartners, handleSubmit: handleSubmitPartners, formState: { errors: errorsPartners } } = useForm<FormDataPartners>({
    resolver: yupResolver(schemaPartners)
  });




  const handleOneSubmit: SubmitHandler<FormDataPF> = async (values, event) => {
    setLoading(true);
    setError('');

    if (values.password !== values.passwordRepeat) {
      setError('As senhas não estão iguais.');
      setLoading(false);
      return;
    }

    const date = values.birthDate.split('/');
    const dateHours = addHours(new Date(`${date[2]}-${date[1]}-${date[0]}`), 3);
    // const dateF = addDays(dateHours, 1)

    const raw = {
      document: values.document.replace(/[/,.,-\s]/g, ""),
      name: values.name,
      email: values.email.toLowerCase(),
      phone: String(values.celular).replace(/[-,\s,(,)]/g, ""),
      birthDate: format(dateHours,'yyyy-MM-dd'),
      password: values.password,
      bank: client.named,
      type: 'PF',
    };

    try {
      // await api.post("/utils/accounts", {
      //   document: values.document.replace(/[/,.,-\s]/g, ""),
      //   email: values.email.toLowerCase(),
      // });

      const { data } = await service.post("/users", raw);

      setLoading(false);
      setStep(2);
      setAccount({...values});
      setToken(data.token.access_token);
    } catch (err) {
      const error = serviceError(err);
      const errorAll = err as any;
      setLoading(false);
      if (error.code === 'document.already' || error.code === 'email.already') {
        setError('Nós já nos conhecemos!\n\nIdentificamos que você já possui um vinculo com a gente.\n\nPara continuar, utilize sua senha e comece a desfrutar de todas as vantangens.');
      } else {
        setError(error.friend || errorAll?.response?.data?.message?.[0]);
      }
    }
  }

  const handleTwoSubmitStepPJ: SubmitHandler<FormDataStep2PF> = async (values, event) => {
    setLoading(true);
    setError('');

    try {
      await service.post("users/token", {
        token: values.token,
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setLoading(false);
      setStep(3);
    } catch (err) {
      // const error = serviceError(err);
      setLoading(false);
      setError('Token inválido.');
    }
  }

  const handleTreeSubmitStepPJ: SubmitHandler<FormDataStep3PF> = async (values, event) => {
    setLoading(true);
    setError('');
    try {

      const date = values.dateStartCompany.split('/');
      const dateHours = addHours(new Date(`${date[2]}-${date[1]}-${date[0]}`), 3);

      const raw = {
        rgNumber: String(values.cnpj).replace(/[/,.,-\s]/g, ""),
        issuing: values.companyName,
        dateOfIssue: format(dateHours,'yyyy-MM-dd'),
        documentUf: values.uf,
        mothersName: values.mother,
        fatherName: values.father,
      }

      await service.post("users/personal/data", {}, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });


      await service.post("users/personal/data/info", raw, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      setLoading(false);
      setStep(5);
    } catch (err) {
      const error = serviceError(err);
      setLoading(false);
      setError(error.friend);
    }
  }

  const handleSubmitStepPartners: SubmitHandler<FormDataPartners> = async (values, event) => {
    setLoading(true);
    setError('');
    try {
      const date = values.birthdate.split('/');
      const dateHours = addHours(new Date(`${date[2]}-${date[1]}-${date[0]}`), 3);
      // const dateF = addDays(dateHours, 1)


      const raw = {
        name: values.name,
        cpf: String(values.cpf).replace(/[/,.,-\s]/g, ""),
        birthdate: format(dateHours,'yyyy-MM-dd'),
        phone: `+55${String(values.phone).replace(/[-,\s,(,)]/g, "")}`,
        mothersName: values.mothersName,
      }


      await service.post("users/partner/data", raw, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      setLoading(false);
      setStep(5);
    } catch (err) {
      const error = serviceError(err);
      setLoading(false);
      setError(error.friend);
    }
  }




  const handleChange = (event: FormEvent, value: number, maskedValue: string) => {
    event.preventDefault();

    setValue(value);
    setMaskedValue(maskedValue);
  };

  async function handleCep(value: string) {
    setCep(value);

    if (value.length >= 9) {
      setLoading(true);

      try {
        const { data } = await axios.get(`https://viacep.com.br/ws/${value}/json/`);
        if (data) {
          setState(data?.uf || '');
          setCity(data?.localidade || '');
          setStreet(data?.logradouro || '');
          setNeighborhood(data?.bairro || '');
        }
        setLoading(false);
        setStep(6);
      } catch (err) {
        setLoading(false);
        setStep(6);
      }
    }
  }

  async function handleAddress(e: FormEvent) {
    e.preventDefault();
    setLoading(true);
    setError('');

    if (!number) {
      setError('Número é obritatório');
      setLoading(false);
      return;
    }

    try {
      await service.post('users/companie/address', {
        zipCode: cep.replace(/[\s,-]/g,""),
        city: city,
        state: state,
        neighborhood: neighborhood,
        street: street,
        number: number,
        complement: complement,
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setLoading(false);
      setStep(7);
    } catch (err) {
      const error = serviceError(err);
      setLoading(false);
      setError(error.friend);
    }
  }

  const handleFileSelect = async (event: any) => {
    setLoading(true);
    setError('');
    const formData = new FormData();
    formData.append("file", event.target?.files[0]);


    try {
      let parseType = '';
      if (typeDoc === 'SELFIE') {
        parseType = 'documentFace';
      }
      else if (typeDoc === 'DRIVER_LICENSE_FRONT' || typeDoc === 'IDENTITY_CARD_FRONT') {
        parseType = 'documentFront';
      }
      else if (typeDoc === 'IDENTITY_CARD_VERSE' || typeDoc === 'DRIVER_LICENSE_VERSE') {
        parseType = 'documentBack';
      } else {
        parseType = 'documentBack';
      }


      const { data: response } = await axios({
        method: "post",
        url: `https://vfbank-api.stric.com.br/documents/upload?type=${parseType}`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data", authorization: `Bearer ${token}` },
      });

      if (response?.user.status && (response.status === 'WAITING_VALIDATION' || response?.user.status === 'WAITING_VALIDATION')) {
        // mensagem de aguardar até 1 dia útil.
        setStep(99);
        setSuccess(true);
      }

      if (typeDoc === 'SELFIE') {
        setTypeDoc('DOCS');
      }

      if (typeDoc === 'DRIVER_LICENSE_FRONT') {
        setTypeDoc('DRIVER_LICENSE_VERSE');
      }

      if (typeDoc === 'IDENTITY_CARD_FRONT') {
        setTypeDoc('IDENTITY_CARD_VERSE');
      }

      // if (typeDoc === 'IDENTITY_CARD_VERSE' || typeDoc === 'DRIVER_LICENSE_VERSE') {
      //   setTypeDoc('DOCUMENT_CARD');
      // }

      // if (typeDoc === 'DOCUMENT_CARD') {
      //   setTypeDoc('ARTICLES_OF_ASSOCIATION');
      // }

      // if (typeDoc === 'ARTICLES_OF_ASSOCIATION') {
      //   setTypeDoc('PROOF_ADDRESS');
      // }


      setLoading(false);
    } catch (err) {
      const error = parseError(err);
      setLoading(false);
      setError(error.friend);
    }

  }

  async function handleResendToken() {
    try {
      setLoadingResend(true);
      await service.post("/users/resend/token", null,  {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setOpenNotification({
        title: "Sucesso",
        notification: 'Novo token enviado',
        type: "success"
      });
      setLoadingResend(false);
    } catch (err) {
      setLoadingResend(false);
      setOpenNotification({
        title: "Erro",
        notification: 'Não foi possível enviar o novo token',
        type: "error"
      });
    }
  }

  return (
    <Container image={client.signin} bank={client.name}>
      <ContentWrapper>
        <WrapperLeft image={client.signin} bank={client.name}></WrapperLeft>
        <WrapperRight image={client.signin} bank={client.name}>
          {/* <GoBackButtonWrapper onClick={handleGoBack}>
            <ArrowLeft />
            voltar
          </GoBackButtonWrapper> */}
          <WrapperContent>
            <Title>
              Você está a poucos passos de uma nova{" "}
              <strong>experiência financeira</strong>
            </Title>
           {step === 1 && (
            <>
            <Text>
              Todos os dados a seguir devem ser obrigatoriamente do
              titular.
            </Text>
            <WrapperForm onSubmit={handleSubmit(handleOneSubmit)}>
              <FormButton
                label="NOME"
                {...register('name')}
                error={errors.name}
              />
              <FormButton
                label="CPF"
                mask="999.999.999-99"
                {...register('document')}
                error={errors.document}
               />
              <FormButton
                label="E-MAIL"
                  {...register('email')}
                  error={errors.email}
                />
              <FormButton
                label="CELULAR"
                mask="(99) 99999-9999"
                {...register('celular')}
                error={errors.celular}
                />

              <FormButton
                label="DATA DE NASCIMENTO"
                mask="99/99/9999"
                {...register('birthDate')}
                error={errors.birthDate}
                />

            <FormButton
                label="SENHA"
                type="password"
                {...register('password')}
                error={errors.password}
              />
              <FormButton
                label="REPITA A SENHA"
                type="password"
                {...register('passwordRepeat')}
                error={errors.passwordRepeat}
              />


                {!!error && (
                <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-5">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <ExclamationIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                    </div>
                    <div className="ml-3">
                      <p className="text-sm text-red-700">
                        {error}
                      </p>
                    </div>
                  </div>
                </div>
                )}

              <WrapperButton>
                <Button type="submit">
                  <Loading isLoading={loading} />
                  Continuar
                </Button>
              </WrapperButton>
            </WrapperForm>
            </>
           )}

           {step === 2 && (
            <>
            <Text>
              Informe o Token enviado no e-mail.
            </Text>
            <WrapperForm onSubmit={handleSubmitStep2PF(handleTwoSubmitStepPJ)}>
              <FormButton
                label="Token (Enviado no e-mail)"
                type="text"
                {...registerStep2PF('token')}
                error={errorsStep2PF.token}
              />

              {/* <WrapperButton> */}
                <ResendButton type="button" onClick={handleResendToken}>
                  <Loading isLoading={loadingResend} />
                  Token inválido? Reenviar token
                </ResendButton>
              {/* </WrapperButton> */}


                {!!error && (
                <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-5">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <ExclamationIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                    </div>
                    <div className="ml-3">
                      <p className="text-sm text-red-700">
                        {error}
                      </p>
                    </div>
                  </div>
                </div>
                )}

              <WrapperButton>
                <Button type="submit">
                  <Loading isLoading={loading} />
                  Continuar
                </Button>
              </WrapperButton>
            </WrapperForm>
            </>
           )}

          {step === 3 && (
            <>
            <Text>
              Todos os dados a seguir devem ser obrigatoriamente do titular.
            </Text>
            <WrapperForm onSubmit={handleSubmitStep3PJ(handleTreeSubmitStepPJ)}>
              <FormButton
                label="Número RG"
                // mask="99.999.999/9999-99"
                {...registerStep3PJ('cnpj')}
                error={errorsStep3PJ.cnpj}
              />
            <FormButton
                label="Emisor RG"
                {...registerStep3PJ('companyName')}
                error={errorsStep3PJ.companyName}
              />
             <FormButton
                label="Data da emissão do RG"
                mask="99/99/9999"
                {...registerStep3PJ('dateStartCompany')}
                error={errorsStep3PJ.dateStartCompany}
              />

            <FormButton
                label="Estado da emissão do RG (UF)"
                maxLength={2}
                {...registerStep3PJ('uf')}
                error={errorsStep3PJ.uf}
              />

            <FormButton
                label="Nome da Mãe"
                {...registerStep3PJ('mother')}
                error={errorsStep3PJ.mother}
              />

            <FormButton
                label="Nome do Pai"
                {...registerStep3PJ('father')}
                error={errorsStep3PJ.father}
              />

                {!!error && (
                <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-5">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <ExclamationIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                    </div>
                    <div className="ml-3">
                      <p className="text-sm text-red-700">
                        {error}
                      </p>
                    </div>
                  </div>
                </div>
                )}

              <WrapperButton>
                <Button type="submit">
                  <Loading isLoading={loading} />
                  Continuar
                </Button>
              </WrapperButton>
            </WrapperForm>
            </>
           )}

        {step === 4 && (
            <>
            <Text>
              Todos os dados a seguir devem ser obrigatoriamente do
              titular (sócio).
            </Text>
            <WrapperForm onSubmit={handleSubmitPartners(handleSubmitStepPartners)}>
            <FormButton
                label="NOME"
                {...registerPartners('name')}
                error={errorsPartners.name}
              />
             <FormButton
                label="CPF"
                mask="999.999.999-99"
                {...registerPartners('cpf')}
                error={errorsPartners.cpf}
              />

              <FormButton
                label="DATA DE NASCIMENTO"
                mask="99/99/9999"
                {...registerPartners('birthdate')}
                error={errorsPartners.birthdate}
              />

            <FormButton
                label="CELULAR"
                mask="(99) 99999-9999"
                {...registerPartners('phone')}
                error={errorsPartners.phone}
              />

            <FormButton
                label="NOME DA MÃE"
                {...registerPartners('mothersName')}
                error={errorsPartners.mothersName}
              />

              {!!error && (
              <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-5">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <ExclamationIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm text-red-700">
                      {error}
                    </p>
                  </div>
                </div>
              </div>
              )}

              <WrapperButton>
                <Button type="submit">
                  <Loading isLoading={loading} />
                  Continuar
                </Button>
              </WrapperButton>
            </WrapperForm>
            </>
           )}

          {step === 5 && (
            <>
            <Text>
              Todos os dados a seguir devem ser obrigatoriamente do
              titular.
            </Text>
            <WrapperForm onSubmit={handleSubmitStep2PF(handleTwoSubmitStepPJ)}>
            <FormButton
              label="CEP"
              mask="99999-999"
              value={cep}
              onChange={(e) => handleCep(e.target.value)}
              />

              {!!error && (
              <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-5">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <ExclamationIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm text-red-700">
                      {error}
                    </p>
                  </div>
                </div>
              </div>
              )}

              <WrapperButton>
                <Button type="submit">
                  <Loading isLoading={loading} />
                  Continuar
                </Button>
              </WrapperButton>
            </WrapperForm>
            </>
           )}

          {step === 6 && (
            <>
            <Text>
              Todos os dados a seguir devem ser obrigatoriamente do
              titular.
            </Text>
            <WrapperForm onSubmit={handleAddress}>
            <FormButton
              label="CEP"
              mask="99999-999"
              value={cep}
              onChange={(e) => handleCep(e.target.value)}
              />

              <div className="flex gap-5 mb-[27px]">
              <FormButton
              nomargin
              label="Cidade"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              />

            <FormButton
              nomargin
              label="Estado"
              value={state}
              maxLength={2}
              onChange={(e) => setState(e.target.value)}
              />
              </div>

              <FormButton
              label="Bairro"
              value={neighborhood}
              onChange={(e) => setNeighborhood(e.target.value)}
              />

            <FormButton
              label="Rua"
              value={street}
              onChange={(e) => setStreet(e.target.value)}
              />

              <div className="flex gap-5 mb-[27px]">
              <FormButton
              nomargin
              label="Número"
              value={number}
              onChange={(e) => setNumber(e.target.value)}
              />

              <FormButton
              nomargin
              label="Complemento"
              value={complement}
              onChange={(e) => setComplement(e.target.value)}
              />
              </div>


              {!!error && (
              <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-5">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <ExclamationIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm text-red-700">
                      {error}
                    </p>
                  </div>
                </div>
              </div>
              )}

              <WrapperButton>
                <Button type="submit">
                  <Loading isLoading={loading} />
                  Continuar
                </Button>
              </WrapperButton>
            </WrapperForm>
            </>
          )}

          {step === 7 && (
            <>
            {typeDoc === 'SELFIE' && (
              <>
                <Title>
                É hora da selfie!
              </Title>
              <Text>
                Precisamos que envie uma foto do seu rosto para ninguém se passar por você.
                Não se preocupe ela é apenas para sua identificação e segurança.
              </Text>

              <Text>
                Somente são aceitos documentos em formato <strong>JPG e JPEG</strong>.
              </Text>
              </>
            )}

{typeDoc !== 'SELFIE' && (
              <>
              {(typeDoc === 'DRIVER_LICENSE_FRONT' || typeDoc === 'IDENTITY_CARD_FRONT') && (
                <>
                  <Title>
                  É hora dos documentos
                </Title>
                <Text>
                  Precisamos que envie agora uma foto da FRENTE do seu documento escolhido anteriormente (CNH ou RG).
                  Não se preocupe, é apenas para sua identificação e segurança.
                </Text>

                <Text>
                  Somente são aceitos documentos em formato <strong>JPG e JPEG</strong>.
                </Text>
                </>
              )}

              {(typeDoc === 'DRIVER_LICENSE_VERSE' || typeDoc === 'IDENTITY_CARD_VERSE') && (
                <>
                  <Title>
                  É hora dos documentos
                </Title>
                <Text>
                Precisamos que envie agora uma foto do VERSO do seu documento escolhido anteriormente (CNH ou RG).
                  Não se preocupe, é apenas para sua identificação e segurança.
                </Text>

                <Text>
                  Somente são aceitos documentos em formato <strong>JPG e JPEG</strong>.
                </Text>
                </>
              )}

            {(typeDoc !== 'DRIVER_LICENSE_VERSE' &&  typeDoc !== 'IDENTITY_CARD_VERSE' && typeDoc !== 'DRIVER_LICENSE_FRONT' && typeDoc !== 'IDENTITY_CARD_FRONT') && (
                <>
                  <Title>
                  É hora dos documentos
                </Title>
                <Text>
                  Precisamos que envie fotos do seu documento (CNH ou RG).
                  Não se preocupe, é apenas para sua identificação e segurança. Escolhe abaixo qual documento vai enviar.
                </Text>

                <Text>
                  Somente são aceitos documentos em formato <strong>JPG e JPEG</strong>.
                </Text>
                </>
              )}
              </>
            )}
            <WrapperForm onSubmit={(e) => e.preventDefault()}>
              {typeDoc === 'DOCS' && (
                <>
                  <Button type="button" style={{ marginBottom: '30px' }} onClick={() => setTypeDoc('DRIVER_LICENSE_FRONT')}>
                      CNH
                  </Button>
                  <Button type="button" onClick={() => setTypeDoc('DRIVER_LICENSE_FRONT')}>
                      RG
                  </Button>
                </>
              )}
              {typeDoc !== 'DOCS' && (
                <>
                 {typeDoc === 'IDENTITY_CARD_VERSE' && (
                  <h3 className="text-2xl mb-5">Ótimo! agora precisamos do verso</h3>
                 )}
                <div className="flex justify-center items-center w-full">
                    <label htmlFor="dropzone-file" className={`${loading ? 'bg-gray-600' : ''} flex flex-col justify-center items-center w-full h-40 rounded-lg border-2 border-dashed cursor-pointer border-cyan-400`}>
                        <div className="flex flex-col justify-center items-center pt-5 pb-6">
                            {loading ? (
                              <>
                              <Loading isLoading />
                            <p className="text-white mt-5">Analisando documento...</p></>
                            ): (
                              <>
                                <svg className="mb-3 w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Clique para fazer upload</span> ou arraste e solte</p>
                                {/* <p className="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p> */}
                              </>
                            )}
                        </div>
                        <input id="dropzone-file" type="file" className="hidden" onChange={handleFileSelect} />
                    </label>
                </div>
                <div className="flex justify-center mt-2">
                {typeDoc === 'DOCUMENT_CARD' && (
                    <p>
                      <ul>
                        <li>Envie o cartão CNPJ com boa definição</li>
                      </ul>
                  </p>
                  )}
                   {typeDoc === 'ARTICLES_OF_ASSOCIATION' && (
                    <p>
                      <ul>
                        <li>Envie o contrato social</li>
                      </ul>
                  </p>
                  )}
                   {typeDoc === 'PROOF_ADDRESS' && (
                    <p>
                      <ul>
                        <li>Envie o comprovante de endereço</li>
                      </ul>
                  </p>
                  )}
                  {typeDoc === 'DRIVER_LICENSE_FRONT' && (
                    <p>
                      <ul>
                        <li>Envie a CNH com boa definição</li>
                        <li><strong>Não</strong> envie seu documento embaçado</li>
                      </ul>
                  </p>
                  )}
                  {typeDoc === 'DRIVER_LICENSE_VERSE' && (
                     <p>
                     <ul>
                       <li>Envie o verso da CNH com boa definição</li>
                       <li><strong>Não</strong> envie seu documento embaçado</li>
                     </ul>
                   </p>
                  )}

                  {typeDoc === 'IDENTITY_CARD_FRONT' && (
                    <p>
                    <ul>
                      <li>Envie o RG com boa definição</li>
                      <li><strong>Não</strong> envie seu documento embaçado</li>
                    </ul>
                  </p>
                  )}
                  {typeDoc === 'IDENTITY_CARD_VERSE' && (
                    <p>
                    <ul>
                      <li>Envie o verso do RG com boa definição</li>
                      <li><strong>Não</strong> envie seu documento embaçado</li>
                    </ul>
                  </p>
                  )}

                  {typeDoc === 'SELFIE' && (
                    <p>
                      <ul>
                        <li>Escolha uma foto com boa iluminação</li>
                        <li><strong>Não</strong> envie fotos com acessórios que escondem o seu rosto</li>
                        <li><strong>Não</strong> envie fotos com mais de uma pessoa</li>
                      </ul>
                    </p>
                  )}
                </div>
                </>

              )}

              {!!error && (
              <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-5 mt-5">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <ExclamationIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm text-red-700">
                      {error}
                    </p>
                  </div>
                </div>
              </div>
              )}
            </WrapperForm>
            </>
          )}

          {step === 99 && (
            <Text>
              Pronto! Agora é só esperar a análise dos dados. Em até 1 dia você irá receber a resposta em seu e-mail.
            </Text>
          )}

          {step === 50 && (
            <Text>
              Infelizmente, após análise interna, o processo de abertura
                  da sua conta digital não foi aprovado.
                  Mas isso não é definitivo. Caso haja interesse, pedimos que
                  refaça o processo novamente após 90 dias.
            </Text>
          )}
          </WrapperContent>
        </WrapperRight>
      </ContentWrapper>
    </Container>
  );
};

