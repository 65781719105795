import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  background-color: var(--gray-2-lp);
  position: relative;
`;

export const ContentWrapper = styled.div`
  max-width: 1216px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 72px 20px 72px 20px;
`;

export const Subtitle = styled.div`
  font-size: 16px;
  font-weight: var(--bold);
  color: var(--gray);
`;

export const Line = styled.div`
  height: 3px;
  width: 15px;
  background: var(--cian);
  margin-top: 12px;
`;

export const SubtitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
`;

export const TitleWrapper = styled.span`
  color: var(--gray);
  font-size: 32px;
  font-weight: var(--light);
  text-align: center;

  > strong {
    font-weight: var(--bold);
  }

  @media (min-width: 840px) {
    font-size: 42px;
  }
`;

export const BadgeWrapper = styled.div`
  position: absolute;

  top: -48px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
`;

export const Box = styled.div`
  background-color: var(--white);
  width: 100%;
  max-width: 800px;
  border-radius: 4px;
  border: 1px solid var(--gray-3);
  margin-top: 42px;
`;

export const BoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
