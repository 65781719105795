import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Header } from "../../components/header";
import ModalReference from "../../components/ModalReference";
import { Sidebar } from "../../components/sidebar";

import { Container, Content } from "./styles";

export const Layout: React.FC = () => {
  const [SidebarMobileIsActive, setSidebarMobileIsActive] = useState(false);
  // const [BellBoxMessageIsActive, setBoxMessageIsActive] = useState(false);
  // const [otherAccountsIsActive, setOtherAccountsIsActive] = useState(false);
  const [modalReferenceIsOpen, setModalReferenceIsOpen] = useState(false);
  const location = useLocation();

  // const HandleOtherAccountsToggle = () => {
  //   setOtherAccountsIsActive((state) => !state);
  // };

  const handleSidebarMobileToggle = () => {
    setSidebarMobileIsActive((state) => !state);
  };

  const handleOpenReferenceModal = () => {
    setModalReferenceIsOpen(true);
  };

  const handleCloseReferenceModal = () => {
    setModalReferenceIsOpen(false);
  };

  useEffect(() => {
    setSidebarMobileIsActive(false);
  }, [location.pathname]);

  return (
    <Container>
      <Sidebar
        handleOpenReferenceModal={handleOpenReferenceModal}
        isActive={SidebarMobileIsActive}
      />

      <Content>
        <Header
          handleSidebarFunction={handleSidebarMobileToggle}
          sidebarIsActive={SidebarMobileIsActive}
        />

        <Outlet />
      </Content>
      <ModalReference
        isOpen={modalReferenceIsOpen}
        handleClose={handleCloseReferenceModal}
      />

    </Container>
  );
};
