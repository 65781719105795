import { format } from "date-fns";
import React, { FormEvent, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "../../components/button";
import InputInsertValue from "../../components/inputValue";
import { StepHeader } from "../../components/stepHeader";
import TextInput from "../../components/TextInput";
import { parseError } from "../../services/api";
import { service } from "../../services/vf";
import { currencyFormat } from "../../utils/currencyFormat";
import { dateFormat } from "../../utils/dateFormat";
import { BoxText } from "../payBillsPayInvoice/styles";

import {
  BoxInfoWrapper,
  ButtonsWrapper,
  Container,
  ExpiresDate,
  InvoiceCode,
  Label,
  PaymentsDate,
  TextAreaInput,
  Title,
  ValidityWrapper,
  WrapperHeader,
} from "./styles";

interface StateProps {
  data: any,
  token: string;
}

export const PayBillsInfoInvoice: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const state = location.state as StateProps;

  const [value, setValue] = useState(300.53);
  const [maskedValue, setMaskedValue] = useState('');
  const [dueAt, setDueAt] = useState(format(new Date(), 'dd/MM/yyyy'));

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleCancel = () => {
    navigate("/paybills");
  };

  const handleConfirmButton = async () => {
    setLoading(true);
    setError('');
    try {
      const { data } = await service.post("/billets/payment", {
        bankSlipPaymentToken: state.token,
      });
    } catch (err) {
        setLoading(false);
        const error = parseError(err);
        setError(error.friend);
    }
    // navigate("/paybills/confirm-invoice");
  };

  const handleChange = (event: FormEvent, value: number, maskedValue: string) => {
    event.preventDefault();

    setValue(value);
    setMaskedValue(maskedValue);
  };

  return (
    <Container>
      <WrapperHeader>
      <StepHeader
        handleGoBackFunction={handleGoBack}
        title="Realizar Pagamento de Boleto "
      />
      </WrapperHeader>
      <BoxInfoWrapper>
        <Title>Código de Barras</Title>
        <InvoiceCode>{state.data.digitLine}</InvoiceCode>
        <ValidityWrapper>
          <ExpiresDate>
            <Label>Vencimento</Label>
            <TextInput borderColor="#E3E3E3" placeholder="02/03/2022" type="text" value={dateFormat(state.data.expirationDate)} disabled />
          </ExpiresDate>
          <PaymentsDate>
            <Label>Pagamento</Label>
            <TextInput borderColor="#E3E3E3" placeholder="02/03/2022" type="text" value={dueAt} disabled />
          </PaymentsDate>
        </ValidityWrapper>
        <ExpiresDate>
            <Label>Valor</Label>
            <TextInput borderColor="#E3E3E3" placeholder="02/03/2022" type="text" value={currencyFormat(state.data.totalValue)} disabled />
          </ExpiresDate>
          <ExpiresDate>
            <Label>Beneficiário</Label>
            <TextInput borderColor="#E3E3E3" placeholder="02/03/2022" type="text" value={state.data.receiverName} disabled />
          </ExpiresDate>
      </BoxInfoWrapper>
      <ButtonsWrapper>
        <Button handleFunction={handleCancel} color="#e3e3e3" title="Cancelar" />
        <Button handleFunction={handleConfirmButton} title="Realizar pagamento" isLoading={loading} />
      </ButtonsWrapper>
      <BoxText>
              {error && (
                <div className="block mt-3 text-red-500">
                  {error}
              </div>
              )}
            </BoxText>
    </Container>
  );
};
