import styled from "styled-components";

import Background from "../../assets/bussinesswoman.jpg";

export const Container = styled.div`
  width: 100%;
  background-color: var(--gray-lp-2);
  background-image: none;
  position: relative;

  @media (min-width: 960px) {
    background-image: url(${Background});
    background-repeat: no-repeat;
    background-size: auto;
  }
`;

export const WrapperContent = styled.div`
  height: 100%;
  max-width: 1216px;
  margin: 0 auto;
  padding: 112px 0;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;

  @media (min-width: 840px) {
    flex-direction: row;
  }
`;

export const WrapperCards = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 32px;
  margin-left: 0;
  width: 100%;
  padding: 0 20px;
  @media (min-width: 960px) {
    grid-template-columns: 1fr 1fr;
    margin-left: 50px;
    max-width: 592px;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardIcon = styled.img`
  width: 48px;
  height: 48px;
  margin-bottom: 16px;
`;

export const CardTitle = styled.strong`
  margin-bottom: 6px;
`;

export const CardText = styled.span`
  font-size: 16px;
  color: var(--gray-1);
  font-weight: var(--light);
  line-height: 28px;
  text-align: start;
`;

export const BadgeWrapper = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -40px;
`;

export const WrapperImageMobile = styled.div`
  margin: 64px 0 -6px 0;
  > img {
    width: 100%;
    height: 100%;
  }

  @media (min-width: 960px) {
    display: none;
  }
`;
