import React, { useEffect, useState } from "react";

import {
  Container,
  HeaderWrapper,
  LogoWrapper,
  NavWrapper,
  OptionNavLink,
  OptionsNavWrapper,
  SidebarButton,
  WrapperButton,
  // WrapperRight,
  InternetBanking,
  OptionsButton,
} from "./styles";

import Logo from "../../assets/logostric.svg";
import { ReactComponent as SidebarIcon } from "../../assets/LPsidebaricon.svg";
import { ReactComponent as CloseIcon } from "../../assets/LPcloseicon.svg";
import ButtonOpenAccount from "../LPbutton";

const Header: React.FC = () => {
  const [sidebarIsActive, setSidebarIsActive] = useState(false);

  const handleSidebarOption = (item: string) => {
    setSidebarIsActive((state) => !state);
  };

  const handleSidebar = () => {
    setSidebarIsActive((state) => !state);
  };

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Container>
      <HeaderWrapper>
        <SidebarButton onClick={handleSidebar}>
          {sidebarIsActive ? <CloseIcon /> : <SidebarIcon />}
        </SidebarButton>

        <LogoWrapper>
          <img src={Logo} alt="Logo da empresa VIP" />
        </LogoWrapper>
        <NavWrapper sidebarIsActive={sidebarIsActive}>
          <OptionsNavWrapper>
            <OptionNavLink
              onClick={() => handleSidebarOption("start")}
              className={
                scrollPosition >= 0 && scrollPosition < 680 ? "active" : ""
              }
            >
              <a href="/home/#start">
                <span>Início</span>
              </a>
            </OptionNavLink>
            <OptionNavLink
              onClick={() => handleSidebarOption("benefits")}
              className={
                scrollPosition >= 680 && scrollPosition < 1488 ? "active" : ""
              }
            >
              <a href="/home/#Product">
                <span>O Produto</span>
              </a>
            </OptionNavLink>
            <OptionNavLink
              onClick={() => handleSidebarOption("howItWorks")}
              className={
                scrollPosition >= 1488 && scrollPosition < 2744 ? "active" : ""
              }
            >
              <a href="/home/#Account">
                <span>Conta Corrente</span>
              </a>
            </OptionNavLink>
            <OptionNavLink
              className={
                scrollPosition >= 2744 && scrollPosition < 3430 ? "active" : ""
              }
              onClick={() => handleSidebarOption("faq")}
            >
              <a href="/home/#Benefits">
                <span>Benefício</span>
              </a>
            </OptionNavLink>
            <OptionNavLink
              onClick={() => handleSidebarOption("partners")}
              className={scrollPosition >= 3430 ? "active" : ""}
            >
              <a href="/home/#FAQ">
                <span>FAQ</span>
              </a>
            </OptionNavLink>
          </OptionsNavWrapper>
          <OptionsButton>
            <InternetBanking href="https://bank.stric.com.br">
              <span>Internet Banking</span>
            </InternetBanking>
            <WrapperButton>
              <ButtonOpenAccount linkTo="/type-create-account" />
            </WrapperButton>
          </OptionsButton>
        </NavWrapper>
      </HeaderWrapper>
    </Container>
  );
};

export default Header;
