import React, { createContext, useCallback, useState, useContext, useEffect } from 'react';

import { service as api, CommonHeaderProperties } from '../services/vf';
import { useClient } from './client';

interface Account {
  id: string;
  name: string;
  document: string;
  email: string;
  status: string;
  accountNumber: string;

  //
  balance: number;
  bank: string;
  branch: string;
  account: string;
  accountDigit: string;
  pin: boolean;
  isPool: boolean;
  type: string;

}

interface User {
  type: string;
  status: string;
  name: string;
  document: string;
}

interface AuthState {
  token: string;
  account: Account;
  user: User;
}

interface SignInCredentials {
  document: string;
  password: string;
}

interface AuthContextData {
  account: Account;
  user: User;
  signIn(credentials: SignInCredentials): Promise<void>;
  signInWithToken(token: string): Promise<void>;
  signOut(): void;
  refresh: () => void;
  refreshAccount: () => void;
  checkStep: (account: Account, token: string) => Promise<boolean | void>;
  // updateBalance(balance: number): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

interface AuthProviderProps {
  children: React.ReactNode;
}


export function AuthProvider({ children }: AuthProviderProps) {
  const { client } = useClient();
  const [data, setData] = useState<AuthState>(() => {
    const token = sessionStorage.getItem('@stricv2:token');
    const account = sessionStorage.getItem('@stricv2:account') as string;
    const user = sessionStorage.getItem('@stricv2:user') as string;


    if (token && account && user) {
      const accountData = JSON.parse(account);
      const userData = JSON.parse(user);

      api.defaults.headers = {
        Authorization: `Bearer ${token}`,
        account: accountData.id,
      } as CommonHeaderProperties;

      return { token, account: accountData, user: userData };
    }

    return {} as AuthState;
  });

  async function checkStep(data: Account, token: string) {
    const type = data.type === 'PJ' ? 'pj' : 'pf';

    if (type === 'pj') {
        if (data.status === 'WAITING_TOKEN') {
          window.localStorage.setItem('@stricv2:status', 'WAITING_TOKEN');
          window.localStorage.setItem('@stricv2:id', token);
          localStorage.removeItem('@stricv2:token');
          localStorage.removeItem('@stricv2:account');
          // window.location.href = `/create-account/?type=${type}`;
          return;
        }

        if (data.status === 'WAITING_CHANGES') {
          window.localStorage.setItem('@stricv2:status', 'WAITING_CHANGES');
          window.localStorage.setItem('@stricv2:id', token);
          localStorage.removeItem('@stricv2:token');
          localStorage.removeItem('@stricv2:account');
          // window.location.href = `/create-account/?type=${type}`;
          return;
        }


        if (data.status === 'WAITING_FLOW') {
          window.localStorage.setItem('@stricv2:status', 'WAITING_FLOW');
          window.localStorage.setItem('@stricv2:id', token);
          localStorage.removeItem('@stricv2:token');
          localStorage.removeItem('@stricv2:account');
          // window.location.href = `/create-account/?type=${type}`;
          return;
        }

        if (data.status === 'WAITING_PARTNERS') {
          window.localStorage.setItem('@stricv2:status', 'WAITING_PARTNERS');
          window.localStorage.setItem('@stricv2:id', token);
          localStorage.removeItem('@stricv2:token');
          localStorage.removeItem('@stricv2:account');
          // window.location.href = `/create-account/?type=${type}`;
          return;
        }

        if (data.status === 'WAITING_ADDRESS') {
          window.localStorage.setItem('@stricv2:status', 'WAITING_ADDRESS');
          window.localStorage.setItem('@stricv2:id', token);
          localStorage.removeItem('@stricv2:token');
          localStorage.removeItem('@stricv2:account');
          // window.location.href = `/create-account/?type=${type}`;
          return;
        }

        if (data.status === 'WAITING_DOCUMENTS' || data.status === 'PENDING') {
          window.localStorage.setItem('@stricv2:status', 'WAITING_DOCUMENTS');
          window.localStorage.setItem('@stricv2:id', token);
          localStorage.removeItem('@stricv2:token');
          localStorage.removeItem('@stricv2:account');
          sessionStorage.removeItem('@stricv2:token');
          sessionStorage.removeItem('@stricv2:account');
          // window.location.href = `/create-account/?type=${type}`;
          return;
        }

        if (data.status === 'WAITING_VALIDATION' || data.status === 'WAITING_APPROVE') {
          window.localStorage.setItem('@stricv2:status', 'WAITING_VALIDATION');
          window.localStorage.setItem('@stricv2:id', token);
          localStorage.removeItem('@stricv2:token');
          localStorage.removeItem('@stricv2:account');
          sessionStorage.removeItem('@stricv2:token');
          sessionStorage.removeItem('@stricv2:account');
          // window.location.href = `/create-account/?type=${type}`;
          return;
        }

        if (
          data.status === 'WAITING_VALIDATION' || data.status === 'WAITING_APPROVE') {
          window.localStorage.setItem('@stricv2:status', 'WAITING_VALIDATION');
          localStorage.removeItem('@stricv2:token');
          localStorage.removeItem('@stricv2:account');
          localStorage.removeItem('@stricv2:token');
          localStorage.removeItem('@stricv2:account');
          sessionStorage.removeItem('@stricv2:token');
          sessionStorage.removeItem('@stricv2:account');
          // window.location.href = `/create-account/?type=${type}`;
          return;
        }

        if (
          data.status === 'UNAPPROVED') {
          window.localStorage.setItem('@stricv2:status', 'UNAPPROVED');
          localStorage.removeItem('@stricv2:token');
          localStorage.removeItem('@stricv2:account');
          localStorage.removeItem('@stricv2:token');
          localStorage.removeItem('@stricv2:account');
          sessionStorage.removeItem('@stricv2:token');
          sessionStorage.removeItem('@stricv2:account');
          // window.location.href = `/create-account/?type=${type}`;
          return;
        }



    }

    if (type === 'pf') {
      if (data.status === 'WAITING_TOKEN') {
        window.localStorage.setItem('@stricv2:status', 'WAITING_TOKEN');
        window.localStorage.setItem('@stricv2:id', token);
        localStorage.removeItem('@stricv2:token');
        localStorage.removeItem('@stricv2:account');
        // window.location.href = `/create-account/?type=${type}`;
        return;
      }

      if (data.status === 'WAITING_CHANGES') {
        window.localStorage.setItem('@stricv2:status', 'WAITING_CHANGES');
        window.localStorage.setItem('@stricv2:id', token);
        localStorage.removeItem('@stricv2:token');
        localStorage.removeItem('@stricv2:account');
        // window.location.href = `/create-account/?type=${type}`;
        return;
      }


      if (data.status === 'WAITING_FLOW') {
        window.localStorage.setItem('@stricv2:status', 'WAITING_FLOW');
        window.localStorage.setItem('@stricv2:id', token);
        localStorage.removeItem('@stricv2:token');
        localStorage.removeItem('@stricv2:account');
        // window.location.href = `/create-account/?type=${type}`;
        return;
      }

      if (data.status === 'WAITING_PARTNERS') {
        window.localStorage.setItem('@stricv2:status', 'WAITING_PARTNERS');
        window.localStorage.setItem('@stricv2:id', token);
        localStorage.removeItem('@stricv2:token');
        localStorage.removeItem('@stricv2:account');
        // window.location.href = `/create-account/?type=${type}`;
        return;
      }

      if (data.status === 'WAITING_ADDRESS') {
        window.localStorage.setItem('@stricv2:status', 'WAITING_ADDRESS');
        window.localStorage.setItem('@stricv2:id', token);
        localStorage.removeItem('@stricv2:token');
        localStorage.removeItem('@stricv2:account');
        // window.location.href = `/create-account/?type=${type}`;
        return;
      }

      if (data.status === 'WAITING_DOCUMENTS' || data.status === 'PENDING') {
        window.localStorage.setItem('@stricv2:status', 'WAITING_DOCUMENTS');
        window.localStorage.setItem('@stricv2:id', token);
        localStorage.removeItem('@stricv2:token');
        localStorage.removeItem('@stricv2:account');
        sessionStorage.removeItem('@stricv2:token');
        sessionStorage.removeItem('@stricv2:account');
        // window.location.href = `/create-account/?type=${type}`;
        return;
      }

      if (data.status === 'WAITING_VALIDATION' || data.status === 'WAITING_APPROVE') {
        window.localStorage.setItem('@stricv2:status', 'WAITING_VALIDATION');
        window.localStorage.setItem('@stricv2:id', token);
        localStorage.removeItem('@stricv2:token');
        localStorage.removeItem('@stricv2:account');
        sessionStorage.removeItem('@stricv2:token');
        sessionStorage.removeItem('@stricv2:account');
        // window.location.href = `/create-account/?type=${type}`;
        return;
      }

      if (data.status === 'WAITING_VALIDATION' || data.status === 'WAITING_APPROVE') {
        window.localStorage.setItem('@stricv2:status', 'WAITING_VALIDATION');
        localStorage.removeItem('@stricv2:token');
        localStorage.removeItem('@stricv2:account');
        localStorage.removeItem('@stricv2:token');
        localStorage.removeItem('@stricv2:account');
        sessionStorage.removeItem('@stricv2:token');
        sessionStorage.removeItem('@stricv2:account');
        // window.location.href = `/create-account/?type=${type}`;
        return;
      }

      if (
        data.status === 'UNAPPROVED') {
        window.localStorage.setItem('@stricv2:status', 'UNAPPROVED');
        localStorage.removeItem('@stricv2:token');
        localStorage.removeItem('@stricv2:account');
        localStorage.removeItem('@stricv2:token');
        localStorage.removeItem('@stricv2:account');
        sessionStorage.removeItem('@stricv2:token');
        sessionStorage.removeItem('@stricv2:account');
        // window.location.href = `/create-account/?type=${type}`;
        return;
      }
  }


    return true;
  }

  async function getAccount() {
    const token = window.localStorage.getItem('@stricv2:token') || window.sessionStorage.getItem('@stricv2:token');
    const account = window.sessionStorage.getItem('@stricv2:account') || window.localStorage.getItem('@stricv2:account');
    const user = window.sessionStorage.getItem('@stricv2:user') || sessionStorage.getItem('@stricv2:user');

    if (token && account && user) {
      const data = JSON.parse(account) as Account;
      const userData = JSON.parse(user) as User;
      try {
        api.defaults.headers = {
          Authorization: `Bearer ${token}`,
        } as CommonHeaderProperties;

        const { data: responseApiAccount } = await api.get('/accounts');

        await checkStep(data, token);

        setData({ token, account: responseApiAccount, user: userData  });
      } catch(err) {
        signOut();
      }
    }
  }

  function refresh() {
    getAccount();

    setTimeout(() => {
      getAccount();
    }, 2000);

    setTimeout(() => {
      getAccount();
    }, 10000);

    setTimeout(() => {
      getAccount();
    }, 60000);
  }

  function refreshAccount() {
    setInterval(() => {
      getAccount();
    }, 60000);
  }


  useEffect(() => {
    getAccount();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signIn = useCallback(async ({ document, password }) => {
    window.localStorage.removeItem('@stricv2:status');
    window.localStorage.removeItem('@stricv2:id');

    const response = await api.post('/auth/session', {
      document,
      password,
    }, {
      headers: {
        client: client.client_id, // "d2afc12c-bc27-440c-96c2-88e006c92ab2"
      }
    });


    const { access_token: token, user: data } = response.data;

    api.defaults.headers = {
      Authorization: `Bearer ${token}`,
    } as CommonHeaderProperties;

    const { data: responseApiAccount } = await api.get("/accounts");

    sessionStorage.setItem('@stricv2:token', token);
    sessionStorage.setItem('@stricv2:account', JSON.stringify(data));
    sessionStorage.setItem('@stricv2:user', JSON.stringify(data));

    api.defaults.headers = {
      Authorization: `Bearer ${token}`,
      // account: data.id,
    } as CommonHeaderProperties;

    await checkStep(data, token);

    setData({ token, account: responseApiAccount, user: data });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const signInWithToken = useCallback(async (token) => {
    window.localStorage.removeItem('@stricv2:status');
    window.localStorage.removeItem('@stricv2:id');

    api.defaults.headers = {
      Authorization: `Bearer ${token}`,
    } as CommonHeaderProperties;


    const { data: user } = await api.get('/users')

    const { data: responseApiAccount } = await api.get("/accounts");

    sessionStorage.setItem('@stricv2:token', token);
    sessionStorage.setItem('@stricv2:account', JSON.stringify(data));


    await checkStep(user, token);

    setData({ token, account: responseApiAccount, user, });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@stricv2:token');
    localStorage.removeItem('@stricv2:account');
    sessionStorage.removeItem('@stricv2:token');
    sessionStorage.removeItem('@stricv2:account');

    setData({} as AuthState);
  }, []);


  return (
    <AuthContext.Provider
      value={{ checkStep: checkStep, account: data.account, signIn, signInWithToken, signOut, refresh, refreshAccount, user: data.user }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}



