import React, { useState } from "react";

import { CardInfo, CardText, CardTitle, Container } from "./styles";
import { ReactComponent as PlusIcon } from "../../assets/LPplusicon.svg";
import { ReactComponent as LessIcon } from "../../assets/LPlessicon.svg";
import { AnimatePresence } from "framer-motion";

interface props {
  number: string;
  title: string;
  text: string;
}

const Card: React.FC<props> = ({ text, title, number }) => {
  const [cardIsActive, setCardIsActive] = useState(false);

  const handleVisibility = () => {
    setCardIsActive((state) => !state);
  };

  return (
    <Container onClick={handleVisibility}>
      <CardInfo>
        <CardTitle>
          <strong>{number}. </strong>
          {title}
        </CardTitle>
        {cardIsActive ? <LessIcon /> : <PlusIcon />}
      </CardInfo>
      <AnimatePresence>
        {cardIsActive && (
          <CardText
            initial={{ y: -100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -100, opacity: 0 }}
            transition={{
              duration: 0.2,
              type: "tween",
            }}
          >
            {text}
          </CardText>
        )}
      </AnimatePresence>
    </Container>
  );
};

export default Card;
