import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  position: relative;
`;

export const ContentWrapper = styled.div`
  max-width: 1216px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 122px 20px 0;

  @media (min-width: 720px) {
    flex-direction: row;
  }
`;
export const TitleWrapper = styled.div`
  max-width: 488px;
  text-align: center;
  @media (min-width: 840px) {
    text-align: unset;
  }
`;

export const Title = styled.span`
  font-size: 32px;
  color: var(--gray);
  font-weight: var(--light);

  span {
    position: relative;
    display: inline-block;
    font-weight: var(--bold);

    &::before {
      content: "";
      background-color: var(--cian);
      width: 100%;
      height: 24px;
      position: absolute;
      z-index: -1;
      bottom: 0;
    }
  }

  @media (min-width: 840px) {
    text-align: unset;
    font-size: 38px;
  }
`;

export const InfoWrapper = styled.div`
  max-width: 488px;
`;

export const Separator = styled.div`
  flex: 1;
  display: none;
  justify-content: center;
  margin: 40px 0;

  @media (min-width: 840px) {
    margin: 0px;
    display: flex;
  }
`;

export const Subtitle = styled.div`
  font-size: 16px;
  font-weight: var(--multi-bold);
  color: var(--gray);
  position: relative;
  text-align: center;

  @media (min-width: 1120px) {
    text-align: start;
  }
`;

export const Line = styled.div`
  height: 3px;
  width: 15px;
  background: var(--cian);
  margin: 0px 0px 14px 0px;

  @media (min-width: 1120px) {
    flex-direction: row;
    align-items: center;
    margin: 0px 12px 0px 0px;
  }
`;

export const SubtitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 28px 0px 28px 0px;
  text-align: center;

  @media (min-width: 1120px) {
    flex-direction: row;
    align-items: center;
    margin: 0px 0px 14px 0px;
    text-align: unset;
  }
`;

export const Text = styled.div`
  font-size: 18px;
  font-weight: var(--regular);
  color: var(--gray-1-lp);
  line-height: 28px;
  margin-bottom: 39px;
`;

export const BadgeWrapper = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -40px;
`;
