import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  background-color: var(--gray);
`;

export const ContentWrapper = styled.div`
  min-height: 120px;

  max-width: 1216px;
  margin: 0 auto;

  padding: 40px 20px;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 720px) {
    flex-direction: row;
    align-items: center;
    padding: 20px;
  }
`;

export const WrapperLeft = styled.div`
  flex: 1;
`;

export const WrapperRight = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  margin-top: 40px;

  @media (min-width: 720px) {
    text-align: start;

    flex-direction: row;
    flex: 2;
    margin-top: 0px;
  }
`;

export const Title = styled.span`
  font-size: 24px;
  color: var(--white);
  font-weight: var(--bold);
  display: block;
  margin-bottom: 9px;
`;

export const Subtitle = styled.span`
  font-size: 14px;
  color: var(--white);
`;

export const Line = styled.div`
  height: 72px;
  width: 1px;
  background-color: var(--white);
  margin-right: 16px;
  display: none;

  @media (min-width: 720px) {
    display: inline-block;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const WrapperLink = styled.a`
  color: var(--cian);
  font-size: 18px;
  display: flex;
  align-items: center;
  text-decoration: underline;
  margin-top: 20px;

  > svg {
    color: var(--cian);
    fill: var(--cian);
  }

  > strong {
    margin: 0 8px;
  }

  @media (min-width: 720px) {
    margin: 0;
  }
`;
