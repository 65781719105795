import React, { InputHTMLAttributes, useState } from "react";
import { useTheme } from "styled-components";
import {
  ArrowIcon,
  Container,
  Option,
  Options,
  SelectedOption,
  Wrapper,
} from "./styles";

interface Props {
  optionSelected: string;
  options: Array<string>;
  handleFunction: (option: string) => void;
  placeholderColor?: string;
  borderColor?: string;
}

const SelectInput: React.FC<Props> = ({
  optionSelected,
  options,
  handleFunction,
  placeholderColor = "#38D2D9",
  borderColor = "#38D2D9",
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenSelect = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleSelectOption = (option: string) => {
    handleFunction(option);
    setIsOpen((prevState) => !prevState);
  };

  return (
    <Container>
      <Wrapper>
        <SelectedOption
          placeholderColor={placeholderColor}
          borderColor={borderColor}
          onClick={handleOpenSelect}
        >
          <span>{optionSelected}</span>
          <ArrowIcon />
        </SelectedOption>
        {isOpen && (
          <Options>
            {options.map((option) => {
              return (
                <Option onClick={() => handleSelectOption(option)} key={option}>
                  <span>{option}</span>
                </Option>
              );
            })}
          </Options>
        )}
      </Wrapper>
    </Container>
  );
};

export default SelectInput;
