export function currencyFormat(currency: number): string {
  if (currency === undefined) {
    return "***";
  };

  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL"
    }).format(currency);
}
