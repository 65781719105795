import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  background-color: var(--gray);
`;

export const ContentWrapper = styled.div`
  min-height: 592px;
  max-width: 1216px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 20px;

  @media (min-width: 720px) {
    flex-direction: row;
  }
`;

export const WrapperLeft = styled.div``;

export const WrapperRight = styled.div`
  display: flex;
`;

export const Divisorleft = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 32px;
  margin-top: 40px;
  z-index: 2;

  > img {
    width: 100%;
  }

  @media (min-width: 840px) {
    margin-top: -80px;
  }
`;

export const DivisorRight = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  margin-bottom: -80px;

  > img {
    width: 100%;
  }
`;

export const BoxLetter = styled.div`
  height: 104px;
  width: 104px;
  background: var(--cian);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin-top: 32px;
  align-self: flex-end;
`;

export const BoxBank = styled.div`
  height: 176px;
  width: 176px;
  background: var(--cian);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin-bottom: 32px;
  align-self: flex-start;
`;

export const Title = styled.div`
  font-size: 32px;
  font-weight: var(--regular);
  color: var(--white);
  max-width: 488px;
  text-align: center;
  margin-bottom: 25px;

  > strong {
    font-weight: var(--bold);
  }

  @media (min-width: 840px) {
    font-size: 48px;
    text-align: unset;
  }
`;

export const Text = styled.div`
  max-width: 416px;

  font-size: 16px;
  font-weight: var(--light);
  line-height: 28px;
  margin-bottom: 39px;
  color: var(--white);
  text-align: center;

  @media (min-width: 840px) {
    text-align: unset;
  }
`;

export const WrapperButton = styled.div`
  width: 100%;

  @media (min-width: 720px) {
    width: 280px;
  }
`;

export const Subtitle = styled.div`
  font-size: 16px;
  font-weight: var(--bold);
  color: var(--white);
  position: relative;
  text-align: center;

  @media (min-width: 1120px) {
    text-align: start;
  }
`;

export const Line = styled.div`
  height: 3px;
  width: 15px;
  background: var(--cian);
  margin: 0px 0px 14px 0px;

  @media (min-width: 1120px) {
    flex-direction: row;
    align-items: center;
    margin: 0px 12px 0px 0px;
  }
`;

export const SubtitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 28px 0px 28px 0px;
  text-align: center;

  @media (min-width: 1120px) {
    flex-direction: row;
    align-items: center;
    margin: 0px 0px 14px 0px;
    text-align: unset;
  }
`;
