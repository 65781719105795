import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Loading } from "../components/loading";
import { useAuth } from "../hooks/auth";

export function SignInToken() {
  const { token } = useParams()
  const navigation = useNavigate();
  const { signInWithToken, user, checkStep, account } = useAuth()

  useEffect(() => {
    async function signIn()  {
      if (token) {
        await signInWithToken(token!)
        if (user && user.status !== 'ACTIVE') {
          await checkStep(account, token!);
          const statusLocal = window.localStorage.getItem('@stricv2:status');
          if (statusLocal) {
            navigation(`/create-account/?type=${user.type.toLocaleLowerCase()}&status=${statusLocal}`)
          }
        }
      }
    }

    signIn();
  }, [token, user])



  return (
    <div style={{
        flex: 1,
        display: 'flex',
        width: 'vw', height: '100vh', alignItems: 'center', justifyContent: 'center', }}>
        <span>
          <Loading isLoading style={{ color: '#000' }}  />
        </span>
    </div>
  )
}
