import React from "react";
import {
  Container,
  Header,
  HeaderWrapper,
  // TransferMoney,
  ContactsWrapper,
  Contact,
  ContactInfo,
} from "./styles";

export function Contacts() {
  return (
    <Container>
      <Header>
        <HeaderWrapper>
          <span>Contatos Frequentes</span>
        </HeaderWrapper>
      </Header>
      <ContactsWrapper>
        <Contact>
          <ContactInfo>
            <span>Não há nenhum contato cadastrado ainda</span>
          </ContactInfo>
        </Contact>
      </ContactsWrapper>
      {/* <ContactsWrapper>
        <Contact>
          <ContactInfo>
            <span>Logística Torres LTDA</span>
            <p>12.492.191/0002.12</p>
          </ContactInfo>
          <TransferMoney />
        </Contact>
        <Contact>
          <ContactInfo>
            <span>Logística Torres LTDA</span>
            <p>12.492.191/0002.12</p>
          </ContactInfo>
          <TransferMoney />
        </Contact>
        <Contact>
          <ContactInfo>
            <span>Logística Torres LTDA</span>
            <p>12.492.191/0002.12</p>
          </ContactInfo>
          <TransferMoney />
        </Contact>
        <Contact>
          <ContactInfo>
            <span>Logística Torres LTDA</span>
            <p>12.492.191/0002.12</p>
          </ContactInfo>
          <TransferMoney />
        </Contact>
      </ContactsWrapper> */}
    </Container>
  );
}
