export function normalizeName(name: string): string {
  const nameLower = name.toLowerCase();
  const arr = nameLower.split(" ");

  const passeName = ["para", "ltda", "me", "recebido", "recebida"];

  for (let i = 0; i < arr.length; i += 1) {
    if (arr[i].length > 2 && !passeName.includes(arr[i])) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
  }

  const str2 = arr.join(" ");

  return str2;
}

export function getFistNameNormalized(name: string): string {
  const nameNormalize = normalizeName(name);
  const arr = nameNormalize.split(" ");

  return arr[0] || "";
}

