import { Link } from "react-router-dom";
import styled from "styled-components";

import {ReactComponent as ArrowRightIconSVG} from "../../assets/arrowRightDeposit.svg"

export const ArrowRightIcon = styled(ArrowRightIconSVG)`
fill: var(--cian);
`

export const Container = styled(Link)`

text-decoration: none;
background: var(--white);
height: 200px;
width: 280px;
padding: 32px;
display: flex;
flex-direction: column;
justify-content: space-between;
border-radius: 8px;

> div {

    display: flex;
    justify-content: space-between;

    > span {
        width: 120px;
        font-family: var(--semiBold);
        color: var(--gray);
        font-size: 16px;
    }

    > svg {
        align-self: flex-end;
        color: var(--cian);
    }
}



`

export const IconWrapper = styled.div`

> svg {
fill: var(--cian);
min-height: 48px;
min-width: 48px;
}

`