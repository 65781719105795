const colors = {
  background: '#F7F6F8',
  'gray-light-line': '#E3E3E3',
  "gray-light-text": '#A3A3A3',
  "gray-light-order": '#B5B7BD',
  "cian-dark": 'rgba(0, 0, 0, .3)',
  "gray": '#142249',
  "gray-light-05": 'rgba(20, 34, 73, .05)',
  "gray-background": '#0E1833',
  "gray-dark": '#282A3A',
  "cian": '#38D2D9',
  "white": '#fff',
  "white-light": 'rgba(255, 255, 255, .3)',
  "white-light-5": 'rgba(255, 255, 255, .05)',
  "green": '#49D294',
  "red": '#E74C3C',
  "line": 'rgba(255, 255, 255, .1)',
  "modalTitle": '#777986',
  "yellow": '#F6BC4D',
  "confirmBackground": '#EAEAEA',
  "borderInput": '#3B4C7E',
  "lineBorder": '#707070',
  "box": '#F2F2F2',
  "boxText": '#999999',
  "loginBackground": '#142249',
  "colorLogin": "#fff",
};

const setup = [
  {
    name: 'VF Bank Digital',
    named: 'vfbank',
    host: "vfbankdigital.com.br",
    client_id: "a9d32ac4-de8d-4f66-9c21-d1878adafa8d",
    // client_id: "e1c81c9f-b51a-4dcb-ad89-bb9af4761965",
    colors: {
      ...colors,
      "gray": '#151518',
      "cian": '#e2af0d',
      "yellow": '#F6BC4D',
      "green": '#e2af0d',
      "loginBackground": '#151518',
    },
    signin: require('../assets/signinimage.png'),
    whiteLogo: {
      "image": require('../assets/clients/vfbank/logo/white.png'),
      "width": 93.3,
      "height": 68.6,
      "maxWidth": 124.4,
      "maxHeight": 91.46,
      "objectFit": "contain"
    },
    darkLogo: {
      "image": require('../assets/clients/vfbank/logo/dark.png'),
      "width": 108,
      "height": 108,
      "maxWidth": 162,
      "maxHeight": 162,
      "objectFit": "contain"
    },
  },
  {
    name: 'Um Bank',
    named: 'vfbank',
    host: "pre-umbank.stric.com.br",
    client_id: "a9d32ac4-de8d-4f66-9c21-d1878adafa8d",
    // client_id: "e1c81c9f-b51a-4dcb-ad89-bb9af4761965",
    colors: {
      ...colors,
      "gray": '#1d1339',
      "cian": '#f16122',
      "yellow": '#f16122',
      "green": '#f16122',
      "loginBackground": '#1d1339',
    },
    signin: require('../assets/signinimage.png'),
    whiteLogo: {
      "image": require('../assets/clients/umbank/logo/white.png'),
      "width": 200,
      "height": 55.67,
      "maxWidth": 200,
      "maxHeight": 55.67,
      "objectFit": "contain"
    },
    darkLogo: {
      "image": require('../assets/clients/umbank/logo/dark.png'),
      "width": 200,
      "height": 55.67,
      "maxWidth": 200,
      "maxHeight": 55.67,
      "objectFit": "contain"
    },
  },
  {
    name: 'Theos Bank',
    named: 'theosbank',
    host: "theosbank.stric.com.br",
    client_id: "a9d32ac4-de8d-4f66-9c21-d1878adafa8d",
    // client_id: "e1c81c9f-b51a-4dcb-ad89-bb9af4761965",
    colors: {
      ...colors,
      "loginBackground": '#382f2d',
      "gray": '#382f2d',
      "cian": '#e31c79',
      "yellow": '#d3bba8',
      "green": '#6d4f47',
      "borderInput": '#d3bba8',
    },
    signin: require('../assets/signinimage.png'),
    whiteLogo: {
      "image": require('../assets/clients/theosbank/logo/white.png'),
      "width": 157.59,
      "height": 27.99,
      "maxWidth": 262.65,
      "maxHeight": 46.65,
      "objectFit": "contain"
    },
    darkLogo: {
      "image": require('../assets/clients/theosbank/logo/dark.png'),
      "width": 263.4,
      "height": 47.45,
      "maxWidth": 263.4,
      "maxHeight": 47.45,
      "objectFit": "contain"
    },
  },
  {
    name: 'Banco Medi',
    named: 'vfbank',
    host: "medi.stric.com.br",
    client_id: "a9d32ac4-de8d-4f66-9c21-d1878adafa8d",
    // client_id: "e1c81c9f-b51a-4dcb-ad89-bb9af4761965",
    colors: {
      ...colors,
      "gray": '#151518',
      "cian": '#143ff8',
      "yellow": '#143ff8',
      "green": '#143ff8',
      "loginBackground": '#151518',
    },
    signin: require('../assets/signinimage.png'),
    whiteLogo: {
      "image": require('../assets/clients/medi/logo/white.png'),
      "width": 904 * 0.10,
      "height": 344 * 0.10,
      "maxWidth": 904 * 0.20,
      "maxHeight": 344 * 0.20,
      "objectFit": "contain"
    },
    darkLogo: {
      "image": require('../assets/clients/medi/logo/dark.png'),
      "width": 904 * 0.10,
      "height": 344 * 0.10,
      "maxWidth": 904 * 0.20,
      "maxHeight": 344 * 0.20,
      "objectFit": "contain"
    },
  },
  {
    name: 'Moncoc Bank',
    named: 'moncocbank',
    host: "moncocbank.stric.com.br",
    client_id: "a9d32ac4-de8d-4f66-9c21-d1878adafa8d",
    // client_id: "e1c81c9f-b51a-4dcb-ad89-bb9af4761965",
    colors: {
      ...colors,
      "gray": '#000',
      "cian": '#7C1306',
      "yellow": '#F6BC4D',
      "green": '#7C1306',
      "loginBackground": '#151518',
    },
    signin: require('../assets/signinimage.png'),
    whiteLogo: {
      "image": require('../assets/clients/moncocbank/logo/white.png'),
      "width": 3019 * 0.05,
      "height": 988 * 0.05,
      "maxWidth": 3019 * 0.10,
      "maxHeight": 988 * 0.10,
      "objectFit": "contain"
    },
    darkLogo: {
      "image": require('../assets/clients/moncocbank/logo/dark.png'),
      "width": 3019 * 0.05,
      "height": 988 * 0.05,
      "maxWidth": 3019 * 0.10,
      "maxHeight": 988 * 0.10,
      "objectFit": "contain"
    },
  },
  {
    name: 'Agroex Bank',
    named: 'vfbank',
    host: "agroex.stric.com.br",
    client_id: "a9d32ac4-de8d-4f66-9c21-d1878adafa8d",
    // client_id: "e1c81c9f-b51a-4dcb-ad89-bb9af4761965",
    colors: {
      ...colors,
      "gray": '#00567f',
      "cian": '#1eae8d',
      "yellow": '#00567f',
      "green": '#00567f',
      "loginBackground": '#202020',
      "colorLogin": "#f5f5f5",
    },
    signin: require('../assets/signinimage-agroex.jpg'),
    whiteLogo: {
      "image": require('../assets/clients/agroex/logo/white.png'),
      "width": 976 * 0.10,
      "height": 180 * 0.10,
      "maxWidth": 976 * 0.20,
      "maxHeight": 180 * 0.20,
      "objectFit": "contain"
    },
    darkLogo: {
      "image": require('../assets/clients/agroex/logo/dark.png'),
      "width": 976 * 0.10,
      "height": 180 * 0.10,
      "maxWidth": 976 * 0.20,
      "maxHeight": 180 * 0.20,
      "objectFit": "contain"
    },
  },
  {
    name: 'I3 Bank',
    named: 'i3bank',
    host: "i3bank.com.br",
    client_id: "a9d32ac4-de8d-4f66-9c21-d1878adafa8d",
    // client_id: "e1c81c9f-b51a-4dcb-ad89-bb9af4761965",
    colors: {
      ...colors,
      "gray": '#151518',
      "cian": '#5015FF',
      "yellow": '#5015FF',
      "green": '#5015FF',
      "loginBackground": '#151518',
      "colorLogin": "#f5f5f5",
    },
    signin: require('../assets/signinimage.png'),
    whiteLogo: {
      "image": require('../assets/clients/i3/logo/white.png'),
      "width": 994 * 0.10,
      "height": 758 * 0.10,
      "maxWidth": 994 * 0.20,
      "maxHeight": 758 * 0.20,
      "objectFit": "contain"
    },
    darkLogo: {
      "image": require('../assets/clients/i3/logo/dark.png'),
      "width": 1008 * 0.10,
      "height": 738 * 0.10,
      "maxWidth": 1008 * 0.20,
      "maxHeight": 738 * 0.20,
      "objectFit": "contain"
    },
  },
  {
    name: 'Metha Bank',
    named: 'methabank',
    host: "methabank.stric.com.br",
    client_id: "a9d32ac4-de8d-4f66-9c21-d1878adafa8d",
    // client_id: "e1c81c9f-b51a-4dcb-ad89-bb9af4761965",
    colors: {
      ...colors,
      "gray": '#222221',
      "cian": '#309ECE',
      "yellow": '#3C529B',
      "green": '#309ECE',
      "loginBackground": '#222221',
    },
    signin: require('../assets/signinimage.png'),
    whiteLogo: {
      "image": require('../assets/clients/mhetabank/logo/white.png'),
      "width": 400 * 0.50,
      "height": 100 * 0.50,
      "maxWidth": 400 * 0.70,
      "maxHeight": 100 * 0.70,
      "objectFit": "contain"
    },
    darkLogo: {
      "image": require('../assets/clients/mhetabank/logo/dark.png'),
      "width": 700 * 0.30,
      "height": 175 * 0.30,
      "maxWidth": 700 * 0.50,
      "maxHeight": 175 * 0.50,
      "objectFit": "contain"
    },
  },
  {
    name: 'Metha Bank',
    named: 'methabank',
    host: "mhetabank.stric.com.br",
    client_id: "a9d32ac4-de8d-4f66-9c21-d1878adafa8d",
    // client_id: "e1c81c9f-b51a-4dcb-ad89-bb9af4761965",
    colors: {
      ...colors,
      "gray": '#222221',
      "cian": '#309ECE',
      "yellow": '#3C529B',
      "green": '#309ECE',
      "loginBackground": '#222221',
    },
    signin: require('../assets/signinimage.png'),
    whiteLogo: {
      "image": require('../assets/clients/mhetabank/logo/white.png'),
      "width": 400 * 0.50,
      "height": 100 * 0.50,
      "maxWidth": 400 * 0.70,
      "maxHeight": 100 * 0.70,
      "objectFit": "contain"
    },
    darkLogo: {
      "image": require('../assets/clients/mhetabank/logo/dark.png'),
      "width": 700 * 0.30,
      "height": 175 * 0.30,
      "maxWidth": 700 * 0.50,
      "maxHeight": 175 * 0.50,
      "objectFit": "contain"
    },
  },
  {
    name: '',
    named: 'cbobank',
    host: "apostadorei.com.br",
    client_id: "a9d32ac4-de8d-4f66-9c21-d1878adafa8d",
    // client_id: "e1c81c9f-b51a-4dcb-ad89-bb9af4761965",
    colors: {
      ...colors,
      "gray": '#142249',
      "cian": '#c89d3f',
      // "yellow": '#F6BC4D',
      "green": '#c89d3f',
      "loginBackground": '#f8f8f8',
      "colorLogin": "#333",
    },
    signin: require('../assets/bg-image.png'),
    whiteLogo: {
      "image": require('../assets/clients/cbobank/logo/white.png'),
      "width": 200,
      "height": 76.8,
      "maxWidth": 200,
      "maxHeight": 76.8,
      "objectFit": "contain"
    },
    darkLogo: {
      "image": require('../assets/clients/cbobank/logo/dark.png'),
      "width": 200,
      "height": 76.8,
      "maxWidth": 200,
      "maxHeight": 76.8,
      "objectFit": "contain"
    },
  },
  {
    name: '',
    named: 'cbobank',
    host: "cbobank.stric.com.br",
    client_id: "a9d32ac4-de8d-4f66-9c21-d1878adafa8d",
    // client_id: "e1c81c9f-b51a-4dcb-ad89-bb9af4761965",
    colors: {
      ...colors,
      "gray": '#142249',
      "cian": '#c89d3f',
      // "yellow": '#F6BC4D',
      "green": '#c89d3f',
      "loginBackground": '#f8f8f8',
      "colorLogin": "#333",
    },
    signin: require('../assets/bg-image.png'),
    whiteLogo: {
      "image": require('../assets/clients/cbobank/logo/white.png'),
      "width": 200,
      "height": 76.8,
      "maxWidth": 200,
      "maxHeight": 76.8,
      "objectFit": "contain"
    },
    darkLogo: {
      "image": require('../assets/clients/cbobank/logo/dark.png'),
      "width": 200,
      "height": 76.8,
      "maxWidth": 200,
      "maxHeight": 76.8,
      "objectFit": "contain"
    },
  },
  {
    name: '',
    named: 'cbobank',
    host: "ib.cbobank.com.br",
    client_id: "a9d32ac4-de8d-4f66-9c21-d1878adafa8d",
    // client_id: "e1c81c9f-b51a-4dcb-ad89-bb9af4761965",
    colors: {
      ...colors,
      "gray": '#142249',
      "cian": '#c89d3f',
      // "yellow": '#F6BC4D',
      "green": '#c89d3f',
      "loginBackground": '#f8f8f8',
      "colorLogin": "#333",
    },
    signin: require('../assets/bg-image.png'),
    whiteLogo: {
      "image": require('../assets/clients/cbobank/logo/white.png'),
      "width": 200,
      "height": 76.8,
      "maxWidth": 200,
      "maxHeight": 76.8,
      "objectFit": "contain"
    },
    darkLogo: {
      "image": require('../assets/clients/cbobank/logo/dark.png'),
      "width": 200,
      "height": 76.8,
      "maxWidth": 200,
      "maxHeight": 76.8,
      "objectFit": "contain"
    },
  },
  {
    name: 'Zix Pay',
    named: 'vfbank',
    host: "zixpay.stric.com.br",
    client_id: "a9d32ac4-de8d-4f66-9c21-d1878adafa8d",
    colors: {
      ...colors,
      "gray": '#317a64',
      "cian": '#317a64',
      // "yellow": '#317a64',
      // "green": '#e2af0d',
      "loginBackground": '#317a64',
    },
    signin: require('../assets/signinimage.png'),
    whiteLogo: {
      "image": require('../assets/clients/zixpay/logo/white.png'),
      "width": 150,
      "height": 48.9,
      "maxWidth": 150,
      "maxHeight": 48.9,
      "objectFit": "contain"
    },
    darkLogo: {
      "image": require('../assets/clients/zixpay/logo/dark.png'),
      "width": 150,
      "height": 48.9,
      "maxWidth": 150,
      "maxHeight": 48.9,
      "objectFit": "contain"
    },
  },

  {
    name: 'Grupo Premium Bank',
    named: 'xicard',
    host: "ib.grupopremiumbank.com.br",
    client_id: "d2afc12c-bc27-440c-96c2-88e006c92ab2::block",
    // client_id: "e1c81c9f-b51a-4dcb-ad89-bb9af4761965",
    colors: {
      ...colors,
      "gray": '#151518',
      "cian": '#c89d3f',
      // "yellow": '#F6BC4D',
      "green": '#c89d3f',
    },
    signin: require('../assets/signinimage.png'),
    whiteLogo: {
      "image": require('../assets/clients/icard/logo/white.png'),
      "width": 192.7,
      "height": 73.75,
      "maxWidth": 192.7,
      "maxHeight": 73.75,
      "objectFit": "contain"
    },
    darkLogo: {
      "image": require('../assets/clients/icard/logo/dark.png'),
      "width": 192.7,
      "height": 73.75,
      "maxWidth": 192.7,
      "maxHeight": 73.75,
      "objectFit": "contain"
    },
  },
  {
    name: 'Grupo Premium Bank',
    named: 'xicard',
    host: "icard.stric.com.br",
    client_id: "d2afc12c-bc27-440c-96c2-88e006c92ab2::block",
    // client_id: "e1c81c9f-b51a-4dcb-ad89-bb9af4761965",
    colors: {
      ...colors,
      "gray": '#151518',
      "cian": '#c89d3f',
      // "yellow": '#F6BC4D',
      "green": '#c89d3f',
    },
    signin: require('../assets/signinimage.png'),
    whiteLogo: {
      "image": require('../assets/clients/icard/logo/white.png'),
      "width": 192.7,
      "height": 73.75,
      "maxWidth": 192.7,
      "maxHeight": 73.75,
      "objectFit": "contain"
    },
    darkLogo: {
      "image": require('../assets/clients/icard/logo/dark.png'),
      "width": 192.7,
      "height": 73.75,
      "maxWidth": 192.7,
      "maxHeight": 73.75,
      "objectFit": "contain"
    },
  },
  {
    name: 'Grupo Premium Bank',
    named: 'xicard',
    host: "ib.icardpay.com.br",
    client_id: "d2afc12c-bc27-440c-96c2-88e006c92ab2::block",
    // client_id: "e1c81c9f-b51a-4dcb-ad89-bb9af4761965",
    colors: {
      ...colors,
      "gray": '#151518',
      "cian": '#c89d3f',
      // "yellow": '#F6BC4D',
      "green": '#c89d3f',
    },
    signin: require('../assets/signinimage.png'),
    whiteLogo: {
      "image": require('../assets/clients/icard/logo/white.png'),
      "width": 100,
      "height": 62.62,
      "maxWidth": 130,
      "maxHeight": 81.4,
      "objectFit": "contain"
    },
    darkLogo: {
      "image": require('../assets/clients/icard/logo/dark.png'),
      "width": 100,
      "height": 62.62,
      "maxWidth": 130,
      "maxHeight": 81.4,
      "objectFit": "contain"
    },
  },
];


export default setup;
