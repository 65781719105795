import React, { useState } from "react";

import {
  ContentWrapper,
  Container,
  Content,
  Table,
  TableWrapper,
  Text,
} from "./styles";

export const Tax: React.FC = () => {
  const [SidebarMobileIsActive, setSidebarMobileIsActive] = useState(false);

  const handleSidebarMobileToggle = () => {
    setSidebarMobileIsActive((state) => !state);
  };

  const temporaryData = [
    {
      title: "MANUTENÇÃO DE CONTAS",
      value: "R$ 0,00",
    },
    {
      title: "ABERTURA DE CONTAS",
      value: "R$ 0,00",
    },
    {
      title: "ENCERRAMENTO DE CONTAS",
      value: "R$ 0,00",
    },
    {
      title: "EMISSÃO DO 1º CARTÃO FÍSICO",
      value: "R$ 0,00",
    },
    {
      title: "EMISSÃO DE CARTÕES FÍSICOS ADICIONAIS",
      value: "R$ 32,30",
    },
    {
      title: "CARTÃO VIRTUAL ",
      value: "R$ 0,00",
    },
    {
      title: "ANUIDADE DO CARTÃO",
      value: "R$ 32,30",
    },
    {
      title: "PAGAMENTO DE CONTAS",
      value: "R$ 0,00",
    },
    {
      title: "GESTÃO DE COBRANÇA",
      value: "R$ 32,30",
    },
    {
      title: "TRANSFERÊNCIAS BANCÁRIAS",
      value: "R$ 0,00",
    },
    {
      title: "TRANSFERÊNCIA PIX",
      value: "R$ 32,30",
    },
    {
      title: "EMISSÃO DE BOLETO",
      value: "R$ 0,00",
    },
    {
      title: "SAQUE",
      value: "R$ 0,00",
    },
    {
      title: "APLICAÇÃO AUTOMÁTICA",
      value: "R$ 0,00",
    },
    {
      title: "RECEBIMENTO VIA TED",
      value: "Grátis",
    },
    {
      title: "RECEBIMENTO VIA CARTÃO",
      value: "Grátis",
    },
  ];

  return (
    <Container>
      <Content>
        <ContentWrapper>
          <Text>
            <span>Nosso preço é simples e transparente, sem taxas ocultas</span>
          </Text>
          <Table>
            <TableWrapper>
              <table>
                <tbody>
                  {temporaryData.map((op) => {
                    return (
                      <tr key={op.title}>
                        <td>{op.title}</td>
                        <td>{op.value}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </TableWrapper>
          </Table>
        </ContentWrapper>
      </Content>
    </Container>
  );
};
