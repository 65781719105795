import React, { FormEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/button";
import FormSelectButton from "../../components/FormSelectButton";
// import SelectInput from "../../components/SelectInput";
import { StepHeader } from "../../components/stepHeader";
import TextInput from "../../components/TextInput";
import { InputSelect, SelectObject } from "../../components/select";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";

import { banks } from "../../utils/banks";

import {
  ButtonWrapper,
  Container,
  Content,
  ContentWrapper,
  Form,
  FormTitle,
  FormWrapper,
  SelectButtonWrapper,
  WrapperHeader,
} from "./styles";
import { api } from "../../services/api";
import { useClient } from "../../hooks/client";

type FormData = {
  document: string;
  name: string;
  agency: string;
  account: string;
  digit: string;
};

const schema = yup.object({
  name: yup.string().required("Nome é obrigatório"),
  document: yup.string().required("Documento é obrigatório"),
  agency: yup.string().required('Agência é obrigatório'),
  account: yup.string().required('Conta é obrigatório'),
  digit: yup.string().required("Dígito é obrigatório.")
}).required();

export const TransferList: React.FC = () => {
  const { client } = useClient();

  const [document, setDocument] = useState('');
  const [loading, setLoading] = useState(false);


  // const handlePersonalAccount = () => {
  //   setButtonSelected("personal");
  // };
  // const handleBusinnessAccount = () => {
  //   setButtonSelected("businness");
  // };

  // const [selectedAccountType, setSelectedAccountType] =
  //   useState("Tipo de conta");

  // const handleAccountTypeSelect = (option: string) => {
  //   setSelectedAccountType(option);
  // };

  // const [selectedBank, setSelectedBank] = useState("Banco");
  // const handleBankSelect = (option: string) => {
  //   setSelectedBank(option);
  // };



  const handleFormSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      const { data } = await api.post("/utils/accounts/search", {
        document: document.replace(/[/,.,-\s]/g, ""),
      });
      setLoading(false);
      navigate('/transfer/confirm', {
        state: {
          name: data.name,
          document: data.document,
          agency: data.branch,
          account: data.account,
          digit: data.accountDigit,
          bank: {
            id: 0,
            name: client.name,
            hidden: client.name,
          }
        }
      });
      return;
    } catch (err) {
      setLoading(false);
    }
  }

  const navigate = useNavigate();

  const handleNextScreen = () => {
    navigate('/transfer/confirm');
  };

  const handleGoBack = () => {
    navigate(-1);
  };



  return (
    <Container>
      <Content>
        <ContentWrapper>
          <WrapperHeader>
          <StepHeader
            handleGoBackFunction={handleGoBack}
            title="Complete os dados"
          />
          </WrapperHeader>
          <Form onSubmit={handleFormSubmit}>
            {/* <SelectButtonWrapper>
              <FormSelectButton
                isSelected={buttonSelected === "personal"}
                onClick={handlePersonalAccount}
                buttonName={"Transferir para Pessoa Física"}
              />
              <FormSelectButton
                isSelected={buttonSelected === "businness"}
                onClick={handleBusinnessAccount}
                buttonName={"Transferir para Pessoa Jurídica"}
              />
            </SelectButtonWrapper> */}

            <FormTitle>Informe o CPF/CNPJ</FormTitle>
            <FormWrapper>


              <div className="mb-5">
                <TextInput
                  mask="CPF_CNPJ"
                  placeholder={"CPF/CNPJ"}
                  onChange={(input: any) => setDocument(input.target.value)}
                  value={document}
                />
              </div>

              {/* <SelectInput
                optionSelected={selectedAccountType}
                handleFunction={handleAccountTypeSelect}
                options={["Conta Corrente", "Conta Poupança"]}
              /> */}
            </FormWrapper>
            <ButtonWrapper>
              <Button
                isLoading={loading}
                type="submit"
                title="Continuar"
              />
            </ButtonWrapper>
          </Form>
        </ContentWrapper>
      </Content>
    </Container>
  );
};
