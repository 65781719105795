import styled from "styled-components";
import { motion } from "framer-motion";
interface props {
  cardIsActive: boolean;
}

export const Container = styled.button`
  padding: 32px;
  background-color: transparent;
  border-style: none;

  & + button {
    border-top: 1px solid var(--gray-3);
  }
`;

export const CardTitle = styled.div`
  font-size: 18px;
  color: var(--gray);
  font-weight: var(--light);
  text-align: start;

  > strong {
    font-weight: var(--bold);
  }
`;

export const CardInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > svg {
    flex-shrink: 0;
    margin-left: 12px;
  }
`;

export const CardText = styled(motion.p)`
  font-size: 16px;
  color: var(--gray-1-lp);
  font-weight: var(--light);
  line-height: 25px;
  text-align: start;
  margin-top: 27px;
`;
