import styled, { css } from "styled-components";
import SignInImage from "../../assets/bg-image.png";
import { ReactComponent as StricLogoSVG } from "../../../assets/stric_logo.svg";

import { ReactComponent as EyeEnabledIconSVG } from "../../../assets/eyeEnabled.svg";
import { ReactComponent as EyeDisabledIconSVG } from "../../../assets/eyeDisabled.svg";
import { Link } from "react-router-dom";

interface WrapperInterface {
  image: string;
  bank: string;
}

export const EyeEnabledIcon = styled(EyeEnabledIconSVG)`
  fill: var(--cian);
`;
export const EyeDisabledIcon = styled(EyeDisabledIconSVG)`
  fill: var(--cian);
`;

export const StricLogo = styled(StricLogoSVG)`
  height: 33px;
`;

export const Container = styled.div<WrapperInterface>`
  ${(props) =>
    props.bank === "Cone Banx"
      ? css<WrapperInterface>`
          background: url(${(props) => props.image}) no-repeat;
          background-size: cover;
        `
      : css`
          background-color: var(--loginBackground);
        `}

  @media (max-width: 720px) {
    background-image: none;
    background-color: #d2d2d2;
  }

  min-height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(${(props) => props.image}) no-repeat;
  background-size: cover;
  background-position: center;
`;

export const WrapperContent = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
`;

export const WrapCol1 = styled.div`
  grid-area: 1 / 1 / 2 / 2;
`;
export const WrapCol2 = styled.div`
  grid-area: 1 / 2 / 2 / 3;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 850px) {
    grid-area: 1 / 1 / 2 / 3;
  }
`;

export const WrapLoginBox = styled.div`
  background-color: #fff;
  webkit-box-shadow: 0px 5px 19px -6px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 0px 5px 19px -6px rgba(0, 0, 0, 0.26);
  box-shadow: 0px 5px 19px -6px rgba(0, 0, 0, 0.26);
  border-radius: 8px;
  height: 550px;
  width: 385px;
  padding: 40px 30px 24px 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 15px;
`;

export const SignInBoxTitle = styled.h1`
  font-size: 1.9rem;
  font-weight: bold;
`;

export const WrapperForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 25px;
`;

export const InputBorder = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #d2d3d3;
  border-radius: 8px;
  padding: 10px 12px;
  margin: 8px 0;
`;

export const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 14px 0;
`;

export const InputCheckGroup = styled.div``;

export const ForgetPassword = styled.a`
  color: var(--colorLogin);
  font-family: var(--semiBold);
  font-size: 16px;
  align-self: center;
`;

export const ButtonSignIn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  background: rgb(224, 99, 83);
  background: linear-gradient(
    180deg,
    rgba(224, 99, 83, 1) 0%,
    rgba(231, 133, 51, 1) 100%
  );
  height: 49px;
  width: 100%;
  color: #fff;
  font-family: var(--semiBold);
  border-style: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
`;

export const WrapperCuttedText = styled.div`
  display: inline-block;
  margin: 22px 0;
`;

export const CuttedLine = styled.div`
  display: flex;
  align-items: center;
`;
export const Line = styled.div`
  flex-grow: 1;
  height: 1px;
  background-color: #e5e5e5;
`;

export const CuttedText = styled.span`
  background-color: white;
  padding: 0 40px;
  color: #767676;
`;

export const WrapperSocialIcons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-item: center;
`;

export const SocialIcon = styled.img`
  height: 40px;
  padding: 0 10px;
`;

export const NotHaveAnAccount = styled(Link)`
  align-self: center;
  color: var(--colorLogin);
  font-family: var(--bold);
  font-size: 16px;
  margin-top: 30px;
`;

export const Error = styled.div`
  color: var(--red);
  /* border: 1px solid var(--colorLogin); */
  border-radius: 4px;
  padding: 6px;
`;
