import React from "react";

import {
  BenefitInfoWrapper,
  BenefitWrapper,
  Container,
  ContentWrapper,
  Line,
  Subtitle,
  SubtitleWrapper,
  WrapperButton,
  WrapperLeft,
  WrapperRight,
} from "./styles";

import manSuprise from "../../assets/mansuprise.png";
import ButtonOpenAccount from "../LPbutton";

import { ReactComponent as CheckIcon } from "../../assets/LPcheckicon.svg";

const SectionBenefits: React.FC = () => {
  return (
    <>
      <Container id="Benefits">
        <ContentWrapper>
          <WrapperLeft>
            <img src={manSuprise} alt="Homem surpreso" />
          </WrapperLeft>
          <WrapperRight>
            <SubtitleWrapper>
              <Line />
              <Subtitle>ABERTURA DE CONTA 100% GRÁTIS E MAIS</Subtitle>
            </SubtitleWrapper>
            <BenefitWrapper>
              <CheckIcon />
              <BenefitInfoWrapper>
                <strong>Pix GRATUITO E ILIMITADO</strong>
              </BenefitInfoWrapper>
            </BenefitWrapper>
            <BenefitWrapper>
              <CheckIcon />
              <BenefitInfoWrapper>
                <strong>TED GRATUITO E ILIMITADO</strong>
              </BenefitInfoWrapper>
            </BenefitWrapper>
            <BenefitWrapper>
              <CheckIcon />
              <BenefitInfoWrapper>
                <strong>BOLETOS gratuitos</strong>
              </BenefitInfoWrapper>
            </BenefitWrapper>
            <BenefitWrapper>
              <CheckIcon />
              <BenefitInfoWrapper>
                <strong>MANUTENÇÃO DE CONTA GRATUITA</strong>
              </BenefitInfoWrapper>
            </BenefitWrapper>
            <WrapperButton>
              <ButtonOpenAccount />
            </WrapperButton>
          </WrapperRight>
        </ContentWrapper>
      </Container>
    </>
  );
};

export default SectionBenefits;
