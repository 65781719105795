import { ExclamationIcon } from "@heroicons/react/solid";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/button";
import SelectInput from "../../components/SelectInput";
import { StepHeader } from "../../components/stepHeader";
import TextInput from "../../components/TextInput";
import { useAuth } from "../../hooks/auth";
import { useNotification } from "../../hooks/notification";
import { api } from "../../services/api";
import { service, serviceError } from "../../services/vf";

import {
  ActionText,
  ButtonsWrapper,
  Container,
  Form,
  FormItem,
  FormWrapper,
  WrapperHeader,
} from "./styles";

export const PixNewKey: React.FC = () => {
  const { account } = useAuth();
  const navigation = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [selectedKey, setSelectedKey] = useState("Tipo de chave");
  const [registered, setRegistered] = useState(false);
  const [mask, setMask] = useState<string | undefined>(undefined);

  const [key, setKey] = useState('');

  const { setOpenNotification } = useNotification();

  useEffect(() => {
    setKey('');
    if (selectedKey === 'CPF/CNPJ') {
      setMask('CPF_CNPJ');
    } else if (selectedKey === 'Celular') {
      setMask('(99) 99999-9999');
    } else {
      setMask(undefined);
    }
  }, [selectedKey]);

  function handleListKey() {
    navigation('/pix/mykeys');
  }


  const handleNextButton = async () => {
    if (selectedKey === 'Tipo de chave') {
      setOpenNotification({
        type: 'error',
        title: 'Tipo inválido',
        notification: 'Selecione uma tipo de chave Pix'
      });

      return;
    }
    setLoading(true);
    setError('');

    let typeSelected;
    let keyFormatted;

    if (selectedKey === 'CPF/CNPJ') {
      typeSelected = 'CPF_CNPJ';
      keyFormatted = key.replace(/[/,.,-\s]/g, "");
    }
    else if (selectedKey === 'Celular') {
      typeSelected = 'PHONE';
      keyFormatted = `+55${key.replace(/[/,.,-\s,(,)]/g, "")}`;
    }
    else if (selectedKey === 'E-mail') {
      typeSelected = 'EMAIL';
      keyFormatted = key;
    }
    else if (selectedKey === 'Chave aleatória') {
      typeSelected = 'EVP';
      keyFormatted = 'RANDOM';
    } else {
      typeSelected = 'EVP';
      keyFormatted = 'RANDOM';
    }


    if (keyFormatted.length <= 0 && typeSelected !== 'EVP') {
      setError('É preciso informar um valor de chave.');
      setLoading(false);
      return;
    }


    try {
      await service.post("pix/keys/create", {
        type: typeSelected,
        key: keyFormatted
      });

      setLoading(false);
      setError('');
      setRegistered(true);
    } catch (err) {
      setLoading(false);
      const error = serviceError(err);
      setError(error.friend || 'Não foi possível registrar a chave Pix nesse momento. Tente novamente');
    }
  };

  const handleGoBack = () => {
    navigation(-1);
  };

  const handleCancelButton = () => {
    navigation("/pix");
  };


  const handleKeySelect = (option: string) => {
    setSelectedKey(option);
  };

  const TitleInput = useMemo(() => {
    switch(selectedKey){
      case "Celular":
      return "Digite o celular"
      case "CPF/CNPJ":
      return "Digite o CPF/CNPJ"
      case "E-mail":
      return "Digite o E-mail"
      case "Chave aleatória":
      return "Digite a chave aleatória"
      default:
      return "Selecione a chave pix"
    }
  }, [selectedKey])

  return (
    <Container>
      <WrapperHeader>
      <StepHeader
        handleGoBackFunction={handleGoBack}
        title="Registrar nova chave"
        subtitle="Selecione um de chave para registrar"
      />
      </WrapperHeader>
      {!registered && (
      <Form>
        <FormWrapper>
          <FormItem>
          <ActionText>Tipo de chave Pix</ActionText>
          <SelectInput
            placeholderColor={'#B5B7BD'}
            borderColor={'#E3E3E3'}
            optionSelected={selectedKey}
            handleFunction={handleKeySelect}
            options={["CPF/CNPJ", "Celular", "E-mail", "Chave aleatória"]}
          />
          </FormItem>
          <FormItem>
            {selectedKey !== 'Chave aleatória' && (
              <>
              <ActionText>
               {TitleInput}
                  {/* <span className="text-sm">Temporariamente chaves do tipo e-mail e celular estão desativadas.</span> */}
                </ActionText>
                <TextInput
                    mask={mask}
                    borderColor="#E3E3E3" borderRadius="4px"
                    value={key}
                    onChange={input => setKey(input.target.value)}
                  />
              </>
            )}
          </FormItem>
        </FormWrapper>
        {!!error && (
              <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mt-5">
              <div className="flex">
                <div className="flex-shrink-0">
                  <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                </div>
                <div className="ml-3">
                  <p className="text-sm text-yellow-700">
                    {error}
                  </p>
                </div>
              </div>
            </div>
        )}
        <ButtonsWrapper>
        <Button
          color="#e3e3e3"
          title="Cancelar"
          handleFunction={handleCancelButton}
        />
        {/* <button type="button" className="bg-[#49D294] px-10 py-2 rounded-md text-white flex" onClick={handleNextButton}>
          <Loading isLoading={loading}/>
          Continuar
        </button> */}

       <Button type="button" isLoading={loading} handleFunction={handleNextButton} title="Registrar" />
      </ButtonsWrapper>
      </Form>
      )}

      {registered && (
        <Form>
          <FormWrapper>
          <FormItem>
          <ActionText>Chave pix em processo de registro</ActionText>
          <div className="text-sm mb-5">
            <span className="mb-2 block">Essa ação pode demorar alguns instantes.</span>
            Acompanhe o status da sua chave na lista de chaves
          </div>
          <Button type="button" isLoading={loading} handleFunction={handleListKey} title="Acompanhar minhas chaves" />
          </FormItem>
          </FormWrapper>
        </Form>
      )}
    </Container>
  );
};
