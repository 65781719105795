import React, { Fragment, FormEvent, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "../../components/button";
import { InputInsertValue } from "../../components/inputValue";
import { StepHeader } from "../../components/stepHeader";
// import {PasswordInput} from "../../components/passwordInput";
// import { useModalPix } from "../../hooks/modalPix";

import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon, ExclamationIcon } from '@heroicons/react/outline'

import { banks } from '../../utils/banks';

import {
  BoxInfoWrapper,
  // BoxPasswordWrapper,
  ButtonsWrapper,
  Container,
  DataWrapper,
  DateComponent,
  InfoWrapper,
  Key,
  // LabelInput,
  Name,
  WrapperBoxContent,
  WrapperHeader,
} from "./styles";
import { documentFormatted } from "../../utils/documentFormatted";
import { format } from "date-fns";
import brasilLocale from 'date-fns/locale/pt-BR';
// import { Loading } from "../../components/loading";
import { useAuth } from "../../hooks/auth";
import { api, parseError } from "../../services/api";
import TextInput from "../../components/TextInput";
import { service } from "../../services/vf";


interface StateProps {
  key: string;
  sourceKey: string;
  account: {
    agency: string;
    branch: string;
    number: string;
    participant: string;
  }
  owner: {
    cpf_cnpj: string;
    document: string;
    name: string;
  }
}

export interface PixProps {
  id: string
  transactionId: string;
  credit: boolean
  amount: number
  description: string
  rateValue: number
  consignorName: string
  consignorDocument: string
  consignorBankNumber: string
  consignorBankBranch: string
  consignorBankAccount: string
  consignorBankAccountDigit: string
  beneficiaryName: string
  beneficiaryDocument: string
  beneficiaryBankNumber: string
  beneficiaryBankBranch: string
  beneficiaryBankAccount: string
  beneficiaryBankAccountDigit: string
  status: string
  date: string
}

export const PixConfirm: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { account, refresh } = useAuth();
  // const { openPixModal } = useModalPix();

  const [open, setOpen] = useState(false);

  const [value, setValue] = useState(0);
  const [maskedValue, setMaskedValue] = useState('');

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const [pixData, setPixData] = useState<PixProps>({} as PixProps);

  const [pinOpen, setPinOpen] = useState(false);
  const [pin, setPin] = useState('');
  const [hasPin, setHasPin] = useState(true);

  const state = location.state as StateProps;



  if (state === null) {
    navigate("/pix");
  }

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleCancelButton = () => {
    navigate("/pix");
  };

  const handleConfirmButton = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      const { data } = await service.post("/transfers/pix", {
        sourceKey: state.sourceKey,
        targetKey: state.key,
        amount: value,
        // pin,
      });

      setPixData(data);
      setLoading(false);
      setOpen(true);
      refresh();
    } catch (err) {
      setLoading(false);
      const error = parseError(err);
      setError(error.friend);
      setHasPin(false);
    }
  };

  function parseAgency() {
    // if (state.ReceiverBankBranch.length === 1) {
    //   return `000${state.ReceiverBankBranch}`;
    // }

    // if (state.ReceiverBankBranch.length === 2) {
    //   return `00${state.ReceiverBankBranch}`;
    // }

    // if (state.ReceiverBankBranch.length === 3) {
    //   return `00${state.ReceiverBankBranch}`;
    // }

    // return state.ReceiverBankBranch;

    return state.account.agency;
  }

  const handleChange = (event: FormEvent, value: number, maskedValue: string) => {
    event.preventDefault();

    setValue(value);
    setMaskedValue(maskedValue);
  };

  function onCloseModal() {
    setOpen(false);
    navigate("/extract");
  }


  return (
    <Container>
      <WrapperHeader>
      <StepHeader
        handleGoBackFunction={handleGoBack}
        title="Confirme e transfira"
      />
      </WrapperHeader>
      <BoxInfoWrapper>
        <WrapperBoxContent>
          <InfoWrapper>
            <Name>
              {state.owner.name.toUpperCase()}<br/>
              {documentFormatted(state.owner.cpf_cnpj)}
              </Name>
            <DataWrapper>
              <span>Banco</span>
              <Key>{state.account.participant} {banks.find(bank => Number(bank.id) === Number(state.account.participant))?.name || null}</Key>
            </DataWrapper>
            <DataWrapper>
              <span>Agência/Conta</span>
              <Key>{parseAgency()} {state.account.number}</Key>
            </DataWrapper>
            <DataWrapper>
              <span>Chave</span>
              <Key>{documentFormatted(state.key)}</Key>
            </DataWrapper>
          </InfoWrapper>
          <DateComponent>
           {format(new Date(), 'dd LLL yyyy', { locale: brasilLocale }).toUpperCase()}
          </DateComponent>
        </WrapperBoxContent>
        <InputInsertValue onChange={(event, value, masked) => handleChange(event, value, masked)} />
      </BoxInfoWrapper>
      <form onSubmit={handleConfirmButton}>
      {/* <BoxPasswordWrapper>
        <LabelInput>Senha</LabelInput>
        <PasswordInput borderRadius="4px" borderColor="#E3E3E3" placeholder="********" />
      </BoxPasswordWrapper> */}

        {!!error && (
              <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mt-5 w-[480px]">
              <div className="flex">
                <div className="flex-shrink-0">
                  <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                </div>
                <div className="ml-3">
                  <p className="text-sm text-yellow-700">
                    {error}
                  </p>
                </div>
              </div>
            </div>
        )}
      <ButtonsWrapper>
        <Button
          handleFunction={handleCancelButton}
          color="#e3e3e3"
          title="Cancelar"
        />

        {/* <button type="submit" className="bg-[#49D294] w-96 px-10 py-2 rounded-md text-white flex justify-center">
          <Loading isLoading={loading}/>
          Continuar
        </button> */}
        {!hasPin ? (
          <Button type="button" isLoading={loading} title="Continuar para senha" handleFunction={() => setPinOpen(true)} />
        ) : (
           <Button type="submit" isLoading={loading} title="Transferir" />
        )}

<Transition.Root show={pinOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setPinOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm sm:w-full sm:p-6">
                <div>
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                    <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      Senha
                    </Dialog.Title>
                    <div className="mt-2">
                    <p className="mt-5 flex justify-center">
                        <div className="w-48 ">
                          <TextInput
                            placeholder="Senha de 4 dígitos"
                            maxLength={4}
                            value={pin}
                            type="password"
                            onChange={input => setPin(input.target.value)}
                            />
                        </div>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                 <Button type="submit" isLoading={loading} title="Continuar" handleFunction={() => {
                  setHasPin(true);
                  setPinOpen(false);
                 }} />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
        </Transition.Root>
      </ButtonsWrapper>
      </form>


      <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onCloseModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                    <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      Transferência Pix realizada
                      <br />

                      {maskedValue}
                    </Dialog.Title>
                    <div className="mt-2 text-left">
                      <p className="text-sm text-gray-500">
                        <h2 className="mb-5 mt-5 text-lg">Dados da conta debitada</h2>
                         {/* <div>
                          <h2>Nome</h2>
                            <h3>{account.name}</h3>
                         </div> */}

                         {/* <div className="mt-5"> */}
                          {/* <h2>Documento</h2>
                            <h3>{documentFormatted(account.document)}</h3>
                         </div> */}

                         <div className="mt-5 flex">
                          <div><h2>Agência</h2>
                          <h3>0001</h3></div>

                          <div className="ml-28"><h2>Conta</h2>
                          <h3>{account.accountNumber}</h3></div>
                         </div>

                         <h2 className="mb-5 mt-5 text-lg">Dados do favorecido</h2>

                         <div>
                          <h2>Nome</h2>
                            <h3>{state.owner.name}</h3>
                         </div>

                         <div className="mt-5">
                          <h2>Documento</h2>
                            <h3>{documentFormatted(state.owner.cpf_cnpj)}</h3>
                         </div>

                         <div className="mt-5">
                          <h2>Banco</h2>
                            <h3>{state.account.participant} {banks.find(bank => Number(bank.id) === Number(state.account.participant))?.name || null}</h3>
                         </div>

                         <div className="mt-5 flex">
                          <div><h2>Agência</h2>
                          <h3>{parseAgency()}</h3></div>

                          <div className="ml-28"><h2>Conta</h2>
                          <h3>{state.account.number}</h3></div>
                         </div>

                         <div className="mt-5">
                          <h2>ID da transação</h2>
                            <h3>{pixData.transactionId}</h3>
                         </div>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                    onClick={() => onCloseModal()}
                  >
                    Ir para o extrato
                  </button>

                  <button
                    type="button"
                    className="mt-5 inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2text-base font-medium  sm:text-sm text-black"
                    // onClick={() => onCloseModal()}
                  >
                    Comprovante disponível no extrato
                  </button>

                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
    </Container>
  );
};
