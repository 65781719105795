import styled from "styled-components";

export const Container = styled.div`
  background: var(--white);
`;

export const ContentWrapper = styled.div`
  max-width: 1216px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;

  @media (min-width: 840px) {
    flex-direction: row;
  }
`;

export const WrapperLeft = styled.div`
  height: 100%;
  order: 1;
  > img {
    max-width: 100%;
    height: 100%;
    margin-bottom: -20px;
  }

  @media (min-width: 840px) {
    order: 0;
  }
`;

export const WrapperRight = styled.div`
  flex: 1;
  flex-direction: column;
  justify-content: center;
`;

export const Subtitle = styled.div`
  font-size: 16px;
  font-weight: var(--extraBold);
  color: var(--gray);
  position: relative;
`;

export const Line = styled.div`
  height: 3px;
  width: 15px;
  background: var(--cian);
  margin-right: 12px;
`;

export const SubtitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0px 0 39px 0;
`;

export const BenefitWrapper = styled.div`
  width: 100%;
  padding: 24px 0;
  display: flex;
  align-items: center;

  & + div {
    border-top: 1px solid var(--gray-3);
  }

  > svg {
    margin-right: 36px;
  }
`;

export const BenefitContentWrapper = styled.div``;

export const BenefitInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  > strong {
    text-transform: uppercase;
    font-size: 16px;
  }

  > span {
    color: var(--gray-1);
    font-size: 16px;
    font-weight: var(--regular);
    line-height: 22px;
  }
`;

export const WrapperButton = styled.div`
  width: 100%;
  margin-bottom: 83px;

  @media (min-width: 720px) {
    width: 280px;
    margin: 34px 0 0 0;
  }
`;
