import React, { useEffect } from "react";

import {
  Container,
  ContentWrapper,
  GoBackButtonWrapper,
  // Line,
  Requirement,
  Text,
  // TextRequirement,
  Title,
  WrapperContent,
  WrapperLeft,
  WrapperRight,
  ListWrapper,
  WrapperButton,
} from "./styles";

import { ReactComponent as ArrowLeft } from "../../assets/LParrowLeft.svg";

// import { ReactComponent as Logo } from "../../assets/logostric.svg";
import ButtonOpenAccount from "../../components/LPbutton";
import { useNavigate } from "react-router-dom";
import { useClient } from "../../hooks/client";

const CreateAccountType: React.FC = () => {
  const { client } = useClient();
  const navigation = useNavigate();
  const handleGoBack = () => {
    navigation(-1);
  };

  useEffect(() => {
    window.localStorage.removeItem('@stricv2:status');
    window.localStorage.removeItem('@stricv2:id');
  }, []);

  return (
    <Container image={client.signin} bank={client.name}>
      <ContentWrapper>
        <WrapperLeft image={client.signin} bank={client.name}></WrapperLeft>
        <WrapperRight image={client.signin} bank={client.name}>
          <GoBackButtonWrapper onClick={handleGoBack}>
            <ArrowLeft />
            voltar ao login
          </GoBackButtonWrapper>
          <WrapperContent>
            {/* <Logo /> */}
            {client.name !== 'Cone Banx' && (
              <img src={client.darkLogo.image} alt={client.name} style={{
                width: client.darkLogo.maxWidth,
                height: client.darkLogo.maxHeight,
                marginBottom: '15px',
                }} />
            )}
            <Title>
              Pronto para ter uma <strong>Conta {client.name}</strong>?
            </Title>
            <Text>
              Antes de seguir selecione o tipo de conta que deseja abrir
            </Text>
            <ListWrapper>
              <Requirement>
              {client.name === 'VF Bank Digital' ? (
                <ButtonOpenAccount
                  linkTo="/type-create-account"
                  buttonText="Sou Pessoa Física (Em Breve)"
                />
                  ): (
                    <ButtonOpenAccount
                    linkTo="/requirements-create-account?type=pf"
                    buttonText="Sou Pessoa Física"
                  />
                  )}
              </Requirement>
              <Requirement>
              <ButtonOpenAccount
                linkTo="/requirements-create-account?type=pj"
                buttonText="Sou Pessoa Jurídica"
              />
              </Requirement>
            </ListWrapper>
            <WrapperButton>
            </WrapperButton>
          </WrapperContent>
        </WrapperRight>
      </ContentWrapper>
    </Container>
  );
};

export default CreateAccountType;
