import React from "react";
import { Button } from "../button";
import {
  ButtonWrapper,
  CloseIcon,
  DownloadIcon,
  InfoModal,
  Modal,
  ModalContent,
  ModalHeader,
  Section,
  SectionAccountinfo,
  SectionCPNJ,
  SectionItem,
  SectionPayerName,
  SectionTitle,
} from "./styles";

interface props {
  modalIsOpen: boolean;
  afterOpenModal: () => void;
  closeModal: () => void;
}

const ModalConfirmOperation: React.FC<props> = ({
  afterOpenModal,
  closeModal,
  modalIsOpen,
}) => {
  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
    >
      <ModalHeader>
        <div>
          <span>Transferência realizada com sucesso!</span>
          <button onClick={closeModal}>
            <CloseIcon />
          </button>
        </div>
      </ModalHeader>
      <ModalContent>
        <InfoModal>
          <div>
            <span>Henrique Sabino dos Santos</span>
            <p>R$ 2.415,00</p>
          </div>
          <span>09 FEV 2022 - 11:23</span>
        </InfoModal>
        <Section>
          <SectionTitle>Pagador</SectionTitle>
          <SectionPayerName>
            <span>NOME</span>
            <p>
              STRIC BCO LTDA à serviços de MARTINS FERRAMENTAS E UTILIDADES LTDA
            </p>
          </SectionPayerName>
          <SectionCPNJ>
            <span>CPNJ</span>
            <p>39.241.1254/0001-23</p>
          </SectionCPNJ>
        </Section>
        <Section>
          <SectionTitle>Pagamento</SectionTitle>
          <SectionItem>
            <span>NOME</span>
            <p>Henrique Sabino Dos Santos</p>
          </SectionItem>
          <SectionItem>
            <span>CPF</span>
            <p>313.245.124-24</p>
          </SectionItem>
          <SectionItem>
            <span>INSTITUIÇÃO</span>
            <p>SANTANDER SA</p>
          </SectionItem>
          <SectionAccountinfo>
            <SectionItem>
              <span>AG</span>
              <p>3155</p>
            </SectionItem>
            <SectionItem>
              <span>CONTA</span>
              <p>31254125-2</p>
            </SectionItem>
          </SectionAccountinfo>
        </Section>
        <ButtonWrapper>
          <Button handleFunction={closeModal} Icon={DownloadIcon} title="Baixar Comprovante" />
        </ButtonWrapper>
      </ModalContent>
    </Modal>
  );
};

export default ModalConfirmOperation;
