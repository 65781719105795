import React from "react";

import {
  Container,
  WrapperContent,
  Card,
  CardIcon,
  CardTitle,
  CardText,
  BadgeWrapper,
  WrapperCards,
  WrapperImageMobile,
} from "./styles";

import pixIcon from "../../assets/LPpixicon.svg";
import paymentOnAppIcon from "../../assets/LPpaymentsonappicon.svg";
import handshakeicon from "../../assets/LPhandshakeicon.svg";
import receiveicon from "../../assets/LPreceiveicon.svg";
import tedAndPixIcon from "../../assets/LPtedandpixicon.svg";
import timeIcon from "../../assets/LPtimeicon.svg";
import Background from "../../assets/LPbussinesswoman.jpg";
import circleSeparator from "../../assets/LPcircleseparator.svg";

const SectionProduct: React.FC = () => {
  return (
    <>
      <Container id="Product">
        <WrapperContent>
          <WrapperCards>
            <Card>
              <CardIcon src={timeIcon} />
              <CardTitle>Otimize seu tempo</CardTitle>
              <CardText>
                Nosso Internet Banking é um parceiro da sua empresa e facilita
                cada transação.
              </CardText>
            </Card>
            <Card>
              <CardIcon src={handshakeicon} />
              <CardTitle>Atendimento personalizado</CardTitle>
              <CardText>
                Temos um time de especialistas prontos para lhe atender de forma
                exclusiva.
              </CardText>
            </Card>
            <Card>
              <CardIcon src={pixIcon} />
              <CardTitle>Pix gratuito e ilimitado</CardTitle>
              <CardText>
                O Pix para a sua empresa é igual à sua conta digital: você não
                paga nada para usar.
              </CardText>
            </Card>
            <Card>
              <CardIcon src={receiveicon} />
              <CardTitle>Praticidade na hora de receber.</CardTitle>
              <CardText>
                Você pode emitir boletos em lote com a integração da API ou no
                padrão CNAB 400.
              </CardText>
            </Card>
            <Card>
              <CardIcon src={paymentOnAppIcon} />
              <CardTitle>Compras e pagamentos no app</CardTitle>
              <CardText>
                Pague boletos na hora ou agende para o momento mais adequado
                para o financeiro.
              </CardText>
            </Card>
            <Card>
              <CardIcon src={tedAndPixIcon} />
              <CardTitle>Receba e faça TEDs e PIX</CardTitle>
              <CardText>
                Realize ou receba transferências e PIX para Contas Stric ou
                outros bancos de forma descomplicada.
              </CardText>
            </Card>
          </WrapperCards>
          <WrapperImageMobile>
            <img src={Background} />
          </WrapperImageMobile>
        </WrapperContent>
        <BadgeWrapper>
          <img src={circleSeparator} alt={"separador de seção"} />
        </BadgeWrapper>
      </Container>
    </>
  );
};

export default SectionProduct;
