import React, { useEffect } from "react";
import Routes from "./routes";
import { GlobalStyle } from "./styles/globalStyle";
import { Hooks } from "./hooks";

import './styles/index.css';
import { Notification } from "./components/notification";

function App() {

  function doSomethingBeforeUnload() {
    localStorage.removeItem('@stricv2:token');
    localStorage.removeItem('@stricv2:account');
    sessionStorage.removeItem('@stricv2:token');
    sessionStorage.removeItem('@stricv2:account');
  }

  function setupBeforeUnloadListener () {
    window.addEventListener("beforeunload", (ev) => {
        ev.preventDefault();
        return doSomethingBeforeUnload();
    });
};

  useEffect(() => {
    setupBeforeUnloadListener();
  }, []);

  return (
    <>
      <Hooks>
        <Routes />
        <GlobalStyle />
        <Notification />
      </Hooks>
    </>
  );
}

export default App;
