import React, { FormEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/button";
import FormSelectButton from "../../components/FormSelectButton";
// import SelectInput from "../../components/SelectInput";
import { StepHeader } from "../../components/stepHeader";
import TextInput from "../../components/TextInput";
import { InputSelect, SelectObject } from "../../components/select";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { banks } from "../../utils/banks";

import {
  ButtonWrapper,
  Container,
  Content,
  ContentWrapper,
  Form,
  FormTitle,
  FormWrapper,
  SelectButtonWrapper,
  WrapperHeader,
} from "./styles";

type FormData = {
  document: string;
  name: string;
  agency: string;
  account: string;
  digit: string;
};

const schema = yup.object({
  name: yup.string().required("Nome é obrigatório"),
  document: yup.string().required("Documento é obrigatório"),
  agency: yup.string().required('Agência é obrigatório'),
  account: yup.string().required('Conta é obrigatório'),
  digit: yup.string().required("Dígito é obrigatório.")
}).required();

export const TransferSubscribe: React.FC = () => {
  const [buttonSelected, setButtonSelected] = useState("personal");
  const [mask, setMask] = useState("999.999.999-99");
  const [bankSelected, setBankSelected] = useState<SelectObject>(banks[0]);

  const { register, handleSubmit, formState: { errors } } = useForm<FormData>({
    resolver: yupResolver(schema)
  });

  const handlePersonalAccount = () => {
    setButtonSelected("personal");
  };
  const handleBusinnessAccount = () => {
    setButtonSelected("businness");
  };

  // const [selectedAccountType, setSelectedAccountType] =
  //   useState("Tipo de conta");

  // const handleAccountTypeSelect = (option: string) => {
  //   setSelectedAccountType(option);
  // };

  // const [selectedBank, setSelectedBank] = useState("Banco");
  // const handleBankSelect = (option: string) => {
  //   setSelectedBank(option);
  // };



  const handleFormSubmit: SubmitHandler<FormData> = async (values, event) => {
    navigate('/transfer/confirm', {
      state: { ...values, bank: bankSelected }
    });

    return;
  }

  const navigate = useNavigate();
  const handleNextScreen = () => {
    navigate('/transfer/confirm');
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (buttonSelected === 'personal') {
      setMask("999.999.999-99");
    } else {
      setMask("99.999.999/9999-99");
    }
  }, [buttonSelected]);

  return (
    <Container>
      <Content>
        <ContentWrapper>
          <WrapperHeader>
          <StepHeader
            handleGoBackFunction={handleGoBack}
            title="Selecione uma das opções"
          />
          </WrapperHeader>
          <Form onSubmit={handleSubmit(handleFormSubmit)}>
            <SelectButtonWrapper>
              <FormSelectButton
                isSelected={buttonSelected === "personal"}
                onClick={handlePersonalAccount}
                buttonName={"Transferir para Pessoa Física"}
              />
              <FormSelectButton
                isSelected={buttonSelected === "businness"}
                onClick={handleBusinnessAccount}
                buttonName={"Transferir para Pessoa Jurídica"}
              />
            </SelectButtonWrapper>

            <FormTitle>Complete os dados</FormTitle>
            <FormWrapper>
            <div className="mb-5">
              <InputSelect
                  label=""
                  selected={bankSelected}
                  options={banks}
                  onChange={setBankSelected}
                  onSearch='Digite um banco para pesquisar'
                />
            </div>

              <div className="mb-5">
                <TextInput
                  placeholder={buttonSelected === 'personal' ? 'CPF' : "CNPJ"}
                  mask={mask}
                  {...register('document')}
                  error={errors.document}
                />
              </div>

              <div className="mb-5">
                <TextInput
                  placeholder="Nome"
                  {...register('name')}
                  error={errors.name}
                />
              </div>

              <div className="mb-5">
                <TextInput placeholder="Agência" mask="9999"
                  {...register('agency')}
                  error={errors.agency}
                />
              </div>

              <div className="mb-5">
              <TextInput
                placeholder="Conta sem dígito (só números)"
                mask="9999999999999999999"
                {...register('account')}
                  error={errors.account}
              />
             </div>

             <div className="mb-5">
                <TextInput
                  placeholder="Dígito (só número)"
                  // mask="9"
                  maxLength={1}
                  {...register('digit')}
                  error={errors.digit}
                />
              </div>
              {/* <SelectInput
                optionSelected={selectedAccountType}
                handleFunction={handleAccountTypeSelect}
                options={["Conta Corrente", "Conta Poupança"]}
              /> */}
            </FormWrapper>
            <ButtonWrapper>
              <Button
                type="submit"
                title="Continuar"
              />
            </ButtonWrapper>
          </Form>
        </ContentWrapper>
      </Content>
    </Container>
  );
};
