import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  background-color: var(--gray-2);
`;

export const ContentWrapper = styled.div`
  max-width: 1216px;
  margin: 0 auto;
  padding: 96px 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 840px) {
    align-items: flex-start;
  }
`;

export const WrapperLeft = styled.div`
  flex: 1;
`;

export const Subtitle = styled.div`
  font-size: 16px;
  font-weight: var(--multi-bold);
  color: var(--gray);
  position: relative;
`;

export const Line = styled.div`
  height: 3px;
  width: 15px;
  background: var(--cian);
  margin-right: 12px;
`;

export const SubtitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0px 0px 52px 0px;
`;

export const Title = styled.div`
  font-size: 38px;
  font-weight: var(--regular);
  color: var(--gray);
  margin-bottom: 25px;
  flex: 1;
  text-align: center;

  > strong {
    font-weight: var(--bold);
  }

  @media (min-width: 840px) {
    text-align: unset;
  }
`;

export const Text = styled.div`
  font-size: 18px;
  font-weight: var(--regular);
  color: var(--gray);
  line-height: 28px;
  margin-bottom: 39px;
  flex: 1;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media (min-width: 720px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const BadgeWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: -48px;
`;

export const Wrapperlink = styled.a`
  display: flex;
  align-items: center;
  align-self: center;

  position: absolute;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);

  @media (min-width: 840px) {
    right: 0;
    top: 60px;
    bottom: unset;
    left: unset;
  }

  > strong {
    text-decoration: underline;
    margin: 0 8px;
    color: var(--gray);
  }

  > svg {
    color: var(--gray);
    fill: var(--gray);
  }
`;
