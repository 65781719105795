import React, { FormEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Loading } from "../../../components/loading";
import { useAuth } from "../../../hooks/auth";
// import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import { ExclamationIcon } from "@heroicons/react/solid";
import InputMask from "react-input-mask";
import { FaUserAlt } from "react-icons/fa";
import { BiShieldQuarter } from "react-icons/bi";
import {
  ButtonSignIn,
  Container,
  Error,
  ForgetPassword,
  FormRow,
  InputBorder,
  InputCheckGroup,
  Line,
  CuttedLine,
  WrapperCuttedText,
  SignInBoxTitle,
  CuttedText,
  WrapCol1,
  WrapCol2,
  WrapLoginBox,
  WrapperContent,
  WrapperForm,
  WrapperSocialIcons,
  SocialIcon,
  NotHaveAnAccount,
} from "./styles";
import { parseError } from "../../../services/api";
import { useClient } from "../../../hooks/client";
import FormButton from "../../../components/LPFormButton";

import GoogleIcon from "../../../assets/google.svg";
import FacebookIcon from "../../../assets/facebook.svg";
import TwitterIcon from "../../../assets/twitter.svg";

export const Custom: React.FC = () => {
  const { client } = useClient();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const navigation = useNavigate();
  const { signIn, account } = useAuth();

  const [document, setDocument] = useState("");
  const [password, setPassword] = useState("");

  const handlePasswordVisibility = () => {
    setIsPasswordVisible((state) => !state);
  };

  useEffect(() => {
    if (account) {
      navigation("/");
    }
  });

  const handleSignIn = async (event: FormEvent) => {
    event.preventDefault();

    if (!document || !password) {
      return;
    }

    try {
      setLoading(true);
      setError("");
      await signIn({
        document: document.replace(/[/,.,-\s]/g, ""),
        password,
      });

      setLoading(false);
      setError("");
      navigation("/");
    } catch (err) {
      const error = parseError(err);
      setLoading(false);
      setError("");
      setError(error.friend);
    }
  };

  return (
    <Container image={client.signin} bank={client.name}>
      <WrapperContent>
        <WrapCol1></WrapCol1>
        <WrapCol2>
          <WrapLoginBox>
            <SignInBoxTitle>Login</SignInBoxTitle>
            <WrapperForm onSubmit={handleSignIn}>
              <InputBorder>
                <InputMask
                  mask="999.999.999-99"
                  maskChar=""
                  onChange={(input: any) => setDocument(input.target.value)}
                  value={document}
                  placeholder="CPF"
                  style={{ outline: "transparent" }}
                />
                <FaUserAlt color="#7b7f82" />
              </InputBorder>
              <InputBorder>
                <input
                  onChange={(input) => setPassword(input.target.value)}
                  value={password}
                  placeholder="Senha"
                  type={isPasswordVisible ? "text" : "password"}
                  style={{ outline: "transparent" }}
                />
                <BiShieldQuarter color="#7b7f82" />
              </InputBorder>
              <FormRow>
                <InputCheckGroup>
                  <label>
                    <input style={{ margin: 4 }} type="checkbox" />
                    Salvar a senha
                  </label>
                </InputCheckGroup>
                <ForgetPassword href="/forgot">
                  Esqueceu a senha?
                </ForgetPassword>
              </FormRow>
              {!!error && (
                <Error>
                  <div className="bg-red-50 border-l-4 border-red-400 p-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <ExclamationIcon
                          className="h-5 w-5 text-red-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-3">
                        <p className="text-sm text-red-700">{error}</p>
                      </div>
                    </div>
                  </div>
                </Error>
              )}
              <ButtonSignIn type="submit">
                <Loading isLoading={loading} />
                ENTRAR
              </ButtonSignIn>
              <WrapperCuttedText>
                <CuttedLine>
                  <Line />
                  <CuttedText>ou</CuttedText>
                  <Line />
                </CuttedLine>
              </WrapperCuttedText>
            </WrapperForm>
            <WrapperSocialIcons>
              <SocialIcon src={FacebookIcon} alt="Facebook" />
              <SocialIcon src={TwitterIcon} alt="Twitter" />
              <SocialIcon src={GoogleIcon} alt="Google" />
            </WrapperSocialIcons>
            <NotHaveAnAccount to="/type-create-account">
              Não tem uma conta? Abrir conta {client.name}
            </NotHaveAnAccount>
          </WrapLoginBox>
        </WrapCol2>
      </WrapperContent>
    </Container>
  );
};
