import React, { Fragment, useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  Container,
  Content,
  AccountStats,
  CardAccountInfo,
  Transactions,
  Items,
  ItemTitle,
  Action,
  ChargeIcon,
  BillIcon,
  TransferIcon,
} from "./styles";

import { Orders } from "../../components/orders";
import { Projection } from "../../components/projection";
import { Contacts } from "../../components/contacts";
import { Card } from "../../components/card";
import { useAuth } from "../../hooks/auth";
// import { normalizeName } from "../../utils/normelizaName";
import { documentFormatted } from "../../utils/documentFormatted";
import { currencyFormat } from "../../utils/currencyFormat";

import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon, ExclamationIcon } from '@heroicons/react/outline'
import TextInput from "../../components/TextInput";
import { api } from "../../services/api";
import { useNotification } from "../../hooks/notification";
import { useNavigate } from "react-router-dom";
import { useClient } from "../../hooks/client";

export function Dashboard() {
  const navigation = useNavigate();
  const { user, account, refreshAccount } = useAuth();
  const { setOpenNotification } = useNotification();
  const { client } = useClient();
  // const [BellBoxMessageIsActive, setBoxMessageIsActive] = useState(false);
  // const [otherAccountsIsActive, setOtherAccountsIsActive] = useState(false);
  // const [SidebarMobileIsActive, setSidebarMobileIsActive] = useState(false);

  // const HandleBoxMessageToggle = () => {
  //   setBoxMessageIsActive((state) => !state);
  // };

  // const HandleOtherAccountsToggle = () => {
  //   setOtherAccountsIsActive((state) => !state);
  // };

  // const handleSidebarMobileToggle = () => {
  //   setSidebarMobileIsActive((state) => !state);
  // };

  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);

  const [isBalanceVisible, setIsBalanceVisible] = useState(() => {
    const visible = localStorage.getItem('visible');

    if (visible && visible === "true") {
      return true;
    } else {
      return false;
    }
  });

  useEffect(() => {
    setInterval(() => {
      const visible = localStorage.getItem('visible');

      if (visible && visible === "true") {
        setIsBalanceVisible(true)
      } else {
        setIsBalanceVisible(false)
      }
    }, 3000);
  }, [])

  const [pin, setPin] = useState('');

  // useEffect(() => {
  //   if (!account.pin) {
  //     setOpen(true);
  //   } else {
  //     setOpen(false);
  //   }
  // }, [account.pin]);


  // MODAL PIN
  // useEffect(() => {
  //   if (!account.pin) {
  //     setOpen(true);
  //   } else {
  //     setOpen(false);
  //   }
  // }, [account]);

  useEffect(() => {
    if (user.status !== 'ACTIVE') {
      const type = String(user.type).toLowerCase();
      navigation(`/create-account/?type=${type}`)
    }
  }, [user, navigation])

  useEffect(() => {
    refreshAccount();
  }, []);


  async function handlePin() {
    try {
      await api.post("accounts/pin", {
        pin,
      });
      setOpen(false);
      setOpenNotification({
        type: 'success',
        title: "Cadastro realizado",
        notification: "Senha de 4 dígitos cadastrada com sucesso"
      })
    } catch(err) {
      setOpenNotification({
        type: 'error',
        title: "Erro!",
        notification: "Apenas números"
      })
    }
  }

  return (
    <>
    <Container>
      <Content>
        <AccountStats>
          <Card title="Saldo na conta"
          value={isBalanceVisible ? currencyFormat(account.balance) : "***"} color={"#e2af0d"} />
          <Card
            title="Rentabilidade do Mês"
            value="***"
            color={"#49D294"}
          />
          <Card
            title="Boletos em aberto"
            value="***"
            color={"#fff"}
            textColor={"#142249"}
          />
          <CardAccountInfo>
            <div>
              <p className="name">{account.name}</p>
              <p className="id">{documentFormatted(account.document)}</p>
             {!account.isPool && (
              <>
                <p className="bankCode">
                  Banco: <strong>324</strong>
                </p>
                <p className="agency">
                  Agência: <strong>0001</strong>
                </p>
              </>
             )}
              <p className="account">
                Conta: <strong>{account.accountNumber}</strong>
              </p>
            </div>
          </CardAccountInfo>
        </AccountStats>

        <Transactions>
          <Orders />
          <Projection />
          <Contacts />
          {client.named !== 'cbobank' && (
            <>
            <Items>
              <Action to="/charge">
                <ItemTitle>Cobrar</ItemTitle>
                <ChargeIcon />
              </Action>
              {/* <Action to="/paybills">
                <ItemTitle>Pagar contas</ItemTitle>
                <BillIcon />
              </Action> */}
              <Action to="/transfer/subscribe">
                <ItemTitle>Transferir</ItemTitle>
                <TransferIcon />
              </Action>
            </Items>
            </>
          )}
        </Transactions>
      </Content>
    </Container>
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100">
                    <ExclamationIcon className="h-6 w-6 text-yellow-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      Senha de 4 dígitos
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-md text-gray-500">
                        Você ainda não cadastrou uma senha de 4 dígitos
                      </p>
                      <p className="text-md text-gray-500">
                        Para continuar cadastre uma senha de 4 dígitos
                      </p>
                      <p className="mt-5 flex justify-center">
                        <div className="w-48 ">
                          <TextInput
                            placeholder="Senha de 4 dígitos"
                            maxLength={4}
                            value={pin}
                            type="password"
                            onChange={input => setPin(input.target.value)}
                            />
                        </div>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 flex justify-center">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                    onClick={handlePin}
                  >
                    Cadastrar
                  </button>
                  {/* <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setOpen(true)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button> */}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
    </>
  );
}
