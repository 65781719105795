import React from "react";

import {
  BadgeWrapper,
  Container,
  ContentWrapper,
  Line,
  Subtitle,
  SubtitleWrapper,
  Text,
  TextWrapper,
  Title,
  Wrapperlink,
} from "./styles";

import { ReactComponent as ArrowRight } from "../../assets/LParrowRight.svg";

const SectionDayaDayAccount: React.FC = () => {
  return (
    <>
      <Container>
        <ContentWrapper>
          <SubtitleWrapper>
            <Line />
            <Subtitle>CONTA CORRENTE DO DIA A DIA</Subtitle>
          </SubtitleWrapper>
          <Wrapperlink href="https://bank.stric.com.br/type-create-account">
            <strong>Abrir conta</strong>
            <ArrowRight />
          </Wrapperlink>
          <TextWrapper>
            <Title>
              Controle{" "}
              <strong>
                todas as <br /> despesas
              </strong>{" "}
              da sua empresa
            </Title>
            <Text>
              Redução de despesas desnecessárias através do gerenciamento delas
              em um só lugar. Visibilidade e controle em tempo real sobre cada
              centavo gasto.
            </Text>
          </TextWrapper>
        </ContentWrapper>
      </Container>
    </>
  );
};

export default SectionDayaDayAccount;
