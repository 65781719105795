import React from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../hooks/auth";
import { useClient } from "../../hooks/client";

import { Default } from "./default";
import { Custom } from "./custom";

export const SignIn: React.FC = () => {
  const { client } = useClient();

  let content;

  switch (client.named) {
    case "cbobank":
      content = <Custom />;
      break;

    default:
      content = <Default />;
      break;
  }

  return <>{content}</>;
};
