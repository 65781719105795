import React from "react";
import { useAuth } from "../../hooks/auth";
import { currencyFormat } from "../../utils/currencyFormat";
import { dateFormat } from "../../utils/dateFormat";
import { 
        ArrowIcon,
        Container,
        Header,
        HeaderWrapper,
        Content,
        FutureProfits,
        FuturePayments,
        Info,
        FutureTransactions,
        ExpectedBalance,
        FuturePayment,
        IconWrapper, 
        FutureReceive
    } from "./styles";

export const Projection = () => {
    const { account } = useAuth();

    return (
        <Container>
            <Header>
                <HeaderWrapper>
                    <span>Projeção</span>
                    <div>
                        <p>7 dias</p>
                        <ArrowIcon/>
                    </div>
                </HeaderWrapper>
            </Header>
            <Content>
                <FutureTransactions>
                    <FutureProfits>
                        <Info>
                            <span>Recebimentos Futuros</span>
                            <p>R$ 0,00</p>
                        </Info>
                        <IconWrapper>
                            <FutureReceive/>
                        </IconWrapper>
                    </FutureProfits>
                    <FuturePayments>
                        <Info>
                            <span>Pagamentos Futuros</span>
                            <p>R$ 0,00</p>
                        </Info>
                        <IconWrapper>
                            <FuturePayment/>
                        </IconWrapper>
                    </FuturePayments>
                </FutureTransactions>
                <ExpectedBalance>
                    <span>{currencyFormat(account.balance)}</span>
                    <p>Saldo previsto para o dia {dateFormat(String(new Date()))}</p>
                </ExpectedBalance>
            </Content>

        </Container>
    )
}
