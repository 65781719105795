import React from "react";
import { ArrowIcon, ChangeIcon, Container, IconWrapper } from "./styles";


interface Props {
  isSelected: boolean;
  name: string;
  id: string;
  handleFunction?: () => void;
}

export const ClientInfoAccount: React.FC<Props> = ({isSelected, name, id, handleFunction}) => {

    return (
        <Container onClick={handleFunction} isSelected={isSelected}>
              <div className="truncate">
                <strong>{name}</strong>
                <p>{id}</p>
              </div>
              {/* <IconWrapper>
                {
                  isSelected ?
                  <ArrowIcon/>
                  :
                  <ChangeIcon/>
                }
              </IconWrapper> */}
        </Container>
    )
}
