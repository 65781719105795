import React, { FormEvent, Fragment, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "../../components/button";
import { InputInsertValue } from "../../components/inputValue";
// import { PasswordInput } from "../../components/passwordInput";
import { StepHeader } from "../../components/stepHeader";
// import TextInput from "../../components/TextInput";
// import { useModalTransfer } from "../../hooks/modalTransfer";
import { documentFormatted } from "../../utils/documentFormatted";



import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/outline'

import { api, parseError } from "../../services/api";

import brasilLocale from 'date-fns/locale/pt-BR';

import {
  BoxContentWrapper,
  BoxInfoWrapper,
  BoxWrapper,
  ButtonsWrapper,
  Container,
  DataWrapper,
  Date as DateComponent,
  InfoWrapper,
  Key,
  // LabelInput,
  Name,
  TextAreaInput,
  // Value,
  WrapperHeader,
} from "./styles";
import { format } from "date-fns";
import { ExclamationIcon } from "@heroicons/react/solid";
import { useAuth } from "../../hooks/auth";
import TextInput from "../../components/TextInput";
import { service } from "../../services/vf";

interface StateProps {
  bank: {
    hidden: string;
      id: number;
      name: string;
  },
  name: string;
  document: string;
  agency: string;
  account: string;
  digit: string;
}

interface PropsTed {
  id: string;
  transactionId: string
}

export const TransferConfirm: React.FC = () => {
  const navigate = useNavigate();
  const { account, refresh } = useAuth();
  // const { openTransferModal } = useModalTransfer();
  const location = useLocation();
  const [value, setValue] = useState(0);
  const [maskedValue, setMaskedValue] = useState('');
  const [error, setError] = useState('');

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [tedData, setTedData] = useState<PropsTed>({} as PropsTed);

  const state = location.state as StateProps;


  const [pinOpen, setPinOpen] = useState(false);
  const [pin, setPin] = useState('');
  const [hasPin, setHasPin] = useState(true);

  if (state === null) {
    navigate("/transfer");
  }

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleCancel = () => {
    navigate("/transfer");
  };

  const handleChange = (event: FormEvent, value: number, maskedValue: string) => {
    event.preventDefault();

    setValue(value);
    setMaskedValue(maskedValue);
  };

  const handleConfirm = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (state.bank.id !== 0) {
        const { data } = await service.post("/transfers", {
          amount: value,
          bank: String(state.bank.id),
	        accountType: "CC",
	        agency: state.agency,
	        account: `${state.account}-${state.digit}`,
	        category: "TED",
          clientName: state.name,
          document: state.document.replace(/[/,.,-\s]/g, ""),
        });
        setTedData(data);
      } else {
        // const { data } = await api.post("/p2p", {
        //   amount: value,
        //   document: state.document.replace(/[/,.,-\s]/g, ""),
        // });

        // setTedData(data);
      }
      setLoading(false);
      setOpen(true);
      refresh();
    } catch (err) {
      setLoading(false);
      const error = parseError(err);
      setError(error.friend);
      setHasPin(false);
    }
  };

  function onCloseModal() {
    setOpen(false);
    navigate("/extract");
  }

  return (
    <Container>
      <WrapperHeader>
      <StepHeader
        handleGoBackFunction={handleGoBack}
        title="Confirme e transfira"
      />
      </WrapperHeader>
      <form onSubmit={handleConfirm}>
      <BoxInfoWrapper>
        <BoxContentWrapper>
          <InfoWrapper>
            <Name>{state.name}</Name>
            <DataWrapper>
              <span>Documento</span>
              <Key>{documentFormatted(state.document)}</Key>
            </DataWrapper>
            <DataWrapper>
              <span>BANCO</span>
              <Key>{state.bank.name}</Key>
            </DataWrapper>
            <DataWrapper>
              <span>AGÊNCIA</span>
              <Key>{state.agency}</Key>
            </DataWrapper>
            <DataWrapper>
              <span>CONTA</span>
              <Key>{state.account}-{state.digit}</Key>
            </DataWrapper>
            {/* <DataWrapper>
              <span>TIPO DE CONTA</span>
              <Key>Corrente</Key>
            </DataWrapper> */}
          </InfoWrapper>
          <DateComponent>
            {format(new Date(), 'dd LLL yyyy HH:mm', { locale: brasilLocale }).toUpperCase()}
          </DateComponent>
        </BoxContentWrapper>
        <InputInsertValue onChange={(event, value, masked) => handleChange(event, value, masked)} />
      </BoxInfoWrapper>
      <BoxWrapper>
        <TextAreaInput placeholder="Descrição *opcional"></TextAreaInput>
        {/* <LabelInput>Senha</LabelInput> */}
          {/* <PasswordInput borderRadius="4px" borderColor="#E3E3E3" placeholder="********" /> */}
      </BoxWrapper>
      {!!error && (
              <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mt-5 w-[480px]">
              <div className="flex">
                <div className="flex-shrink-0">
                  <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                </div>
                <div className="ml-3">
                  <p className="text-sm text-yellow-700">
                    {error}
                  </p>
                </div>
              </div>
            </div>
        )}
      <ButtonsWrapper>
        <Button
          handleFunction={handleCancel}
          color="#e3e3e3"
          title="Cancelar"
        />
        {!hasPin ? (
          <Button type="button" isLoading={loading} title="Continuar para senha" handleFunction={() => setPinOpen(true)} />
        ) : (
           <Button type="submit" isLoading={loading} title="Transferir" />
        )}

        <Transition.Root show={pinOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setPinOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm sm:w-full sm:p-6">
                <div>
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                    <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      Senha
                    </Dialog.Title>
                    <div className="mt-2">
                    <p className="mt-5 flex justify-center">
                        <div className="w-48 ">
                          <TextInput
                            placeholder="Senha de 4 dígitos"
                            maxLength={4}
                            value={pin}
                            type="password"
                            onChange={input => setPin(input.target.value)}
                            />
                        </div>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                 <Button type="submit" isLoading={loading} title="Continuar" handleFunction={() => {
                  setHasPin(true);
                  setPinOpen(false);
                 }} />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
      </Transition.Root>




      </ButtonsWrapper>
      </form>
      <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onCloseModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                    <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      Transferência realizada
                      <br />

                      {maskedValue}
                    </Dialog.Title>
                    <div className="mt-2 text-left">
                      <p className="text-sm text-gray-500">
                        <h2 className="mb-5 mt-5 text-lg">Dados da conta debitada</h2>
                         {/* <div>
                          <h2>Nome</h2>
                            <h3>{account.name}</h3>
                         </div>

                         <div className="mt-5">
                          <h2>Documento</h2>
                            <h3>{documentFormatted(account.document)}</h3>
                         </div> */}

                         <div className="mt-5 flex">
                          <div><h2>Agência</h2>
                          <h3>0001</h3></div>

                          <div className="ml-28"><h2>Conta</h2>
                          <h3>{account.accountNumber}</h3></div>
                         </div>

                         <h2 className="mb-5 mt-5 text-lg">Dados do favorecido</h2>

                         <div>
                          <h2>Nome</h2>
                            <h3>{state.name}</h3>
                         </div>

                         <div className="mt-5">
                          <h2>Documento</h2>
                            <h3>{documentFormatted(state.document)}</h3>
                         </div>

                         <div className="mt-5">
                          <h2>Banco</h2>
                            <h3>{state.bank.name}</h3>
                         </div>

                         <div className="mt-5 flex">
                          <div><h2>Agência</h2>
                          <h3>{state.agency}</h3></div>

                          <div className="ml-28"><h2>Conta</h2>
                          <h3>{state.account}-{state.digit}</h3></div>
                         </div>

                         <div className="mt-5">
                          <h2>ID da transação</h2>
                            <h3>{tedData.transactionId}</h3>
                         </div>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                    onClick={() => onCloseModal()}
                  >
                    Ir para o extrato
                  </button>

                  <button
                    type="button"
                    className="mt-5 inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2text-base font-medium  sm:text-sm text-black"
                    // onClick={() => onCloseModal()}
                  >
                    Comprovante disponível no extrato
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
    </Container>
  );
};
