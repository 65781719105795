import React from "react";

import { Container } from "./styles";

interface props {
  buttonText?: string;
  linkTo?: string;
}

const ButtonOpenAccount: React.FC<props> = ({
  buttonText = "Abrir minha conta",
  linkTo = "/type-create-account",
}) => {
  return (
    <>
      <Container href={linkTo}>
        <span>{buttonText}</span>
      </Container>
    </>
  );
};

export default ButtonOpenAccount;
