import React, { InputHTMLAttributes, useState } from 'react';

import { Container, EyeDisabledIcon, EyeEnabledIcon } from './styles';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    borderColor?: string;
    borderRadius?: string;
    height?: string;
}

export const PasswordInput: React.FC<Props> = ({
    borderColor = '#777986',
    borderRadius = '8px',
    height = '40px',
    ...rest
}) => {

    const [isPasswordVisible, setIsPasswordVisible] = useState(false)

    const handlePasswordVisiblity = () => {
        setIsPasswordVisible(state => !state)
    }

  return (

    <Container height={height} borderColor={borderColor} borderRadius={borderRadius}> 
        <input type={isPasswordVisible ? 'text' : 'password'} {...rest} />
        <button onClick={handlePasswordVisiblity} >
        {
            isPasswordVisible ? <EyeDisabledIcon/> : <EyeEnabledIcon/>
        }
        </button>
    </Container>

  )
}
