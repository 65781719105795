import styled from "styled-components";

interface props {
  sidebarIsActive: boolean;
}

export const Container = styled.header`
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;
  width: 100%;

  background-color: rgba(255, 255, 255, 0.8);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);

  @media (min-width: 840px) {
  }
`;

export const HeaderWrapper = styled.div`
  padding: 16px 20px;
  max-width: 1216px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 0;
  flex: 1;
  @media (min-width: 840px) {
    flex: 0;
    margin-right: 66px;
  }
`;

export const OptionsNavWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  flex: 2;

  @media (min-width: 840px) {
    padding: 0;
    flex-direction: row;
    flex: unset;
  }
`;

export const NavWrapper = styled.div<props>`
  margin-left: ${(props) => (props.sidebarIsActive ? "0" : "-100%")};
  position: absolute;
  transition: all 0.2s;
  z-index: -1;
  height: 100vh;
  width: 100%;
  top: 50px;
  left: 0;
  right: 0;
  padding: 40px 20px;
  background-color: var(--white);
  display: flex;
  flex-direction: column;

  @media (min-width: 840px) {
    flex-direction: row;
    min-height: unset;
    margin: 0;
    position: static;
    width: auto;
    height: auto;
    z-index: 0;
    background: transparent;
    padding: 0 0;
    flex: 1;
    justify-content: space-between;
  }
`;

export const OptionNavLink = styled.div`
  position: relative;
  padding: 16px 0;
  background-color: transparent;
  border-style: none;

  > a {
    height: 100%;
    text-decoration: none;

    > span {
      color: var(--gray);
      font-size: 16px;
    }
  }

  @media (min-width: 720px) {
    &.active {
      &::after {
        content: "";
        height: 2px;
        width: 25px;
        background-color: var(--cian);
        position: absolute;
        top: -16px;
        right: 0;
        left: 0;
        margin-right: auto;
        margin-left: auto;
      }
    }

    & + div {
      margin-left: 32px;
    }
  }

  &.active {
    > a {
      text-decoration: underline;
    }
  }
`;

export const WrapperButton = styled.div`
  width: 100%;
  @media (min-width: 840px) {
    width: 176px;
    margin-left: 32px;
  }
`;

export const SidebarButton = styled.button`
  background-color: transparent;
  border-style: none;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 840px) {
    display: none;
  }
`;

export const WrapperRight = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
`;

export const InternetBanking = styled.a`
  font-size: 16px;
  color: var(--gray);
  font-weight: var(--extraBold);
  text-decoration: underline;
  margin-bottom: 32px;

  @media (min-width: 840px) {
    margin-bottom: 0;
  }
`;

export const OptionsButton = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 32px;

  @media (min-width: 840px) {
    flex-direction: row;
    flex: unset;
    margin-bottom: 0px;
  }
`;
