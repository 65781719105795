import React, { FormEvent, InputHTMLAttributes } from 'react';

import { Container } from './styles';

interface props extends InputHTMLAttributes<HTMLInputElement> {
    handleFunctionInput?: (e: FormEvent) => void;
}

export const NumberToken: React.FC<props> = ({
    handleFunctionInput,
    ...rest
}) => {

  return (
  <Container>
      <input {...rest}
      onChange={handleFunctionInput}
      min={1} max={1}
      maxLength={1}
      type={'number'}
      ></input>
  </Container>
    )
}
