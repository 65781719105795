import React, { FormEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Loading } from "../../components/loading";
import { useAuth } from "../../hooks/auth";
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import { ExclamationIcon } from '@heroicons/react/solid';

import {
  ButtonEyeVisibility,
  ButtonSignIn,
  Container,
  Content,
  EyeDisabledIcon,
  EyeEnabledIcon,
  ForgetPassword,
  Input,
  InputPassword,
  Line,
  NotHaveAnAccount,
  Reference,
  Separator,
  // StricLogo,
  Title,
  WrapperBackground,
  WrapperContent,
  WrapperForm,
  WrapperLogo,
  Error,
} from "./styles";
import { api, parseError } from "../../services/api";
import { useClient } from "../../hooks/client";
import { CheckIcon } from "@heroicons/react/outline";
import { service } from "../../services/vf";


export const Forgot: React.FC = () => {
  const { client } = useClient();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const navigation = useNavigate();
  const location = useLocation();
  const { signIn, account } = useAuth();

  const [document, setDocument] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');

  const [ok, setOk] = useState(false);

  const [success, setSuccess] = useState('');

  const [step, setStep] = useState(1);
  const [token, setToken] = useState('');

  const handlePasswordVisibility = () => {
    setIsPasswordVisible((state) => !state);
  };

  useEffect(() => {
    if (account) {
      navigation("/");
    }
  });




  const handleSignIn = async (event: FormEvent) => {
    event.preventDefault();

    if (!document) {
      return;
    }

    try {
      setLoading(true);
      setError('');

      await service.post("forgot-password/send/token", {
        email: document,
      });

      setLoading(false);
      setError('');
      setStep(2);
      // setSuccess('Foi enviado as instruções de recuperarção de senha para o seu e-mail.');

    } catch (err) {
      const error = parseError(err);
      setLoading(false);
      setError('');
      setError(error.friend);
    }
  };


  const handleReset = async (event: FormEvent) => {
    event.preventDefault();

    if (!password) {
      return;
    }

  if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/.test(password)) {
    setError("A senha deve conter 8 caracteres, uma maiúscula, uma minúscula, um número e um caractere especial.");
    return;
  }

    if (password !== passwordConfirm) {
      setError('As senhas não conferem.');
      return;
    }

    try {
      setLoading(true);
      setError('');
      await service.post("/forgot-password/send/password", {
        email: document,
        confirmationCode,
        password,
      });

      setLoading(false);
      setError('');
      setSuccess('Nova senha cadastrada com sucesso.');
      setOk(true);
    } catch (err) {
      const error = parseError(err);
      setLoading(false);
      setError('Código de senha de redefinição de confirmação de usuário inválido');
      // setError(error.friend);
    }
  };

  return (
    <Container>
      <WrapperBackground />
      <WrapperContent>
        <Content>
          <WrapperLogo>
            <img src={client.whiteLogo.image} alt={client.name} style={{
              width: client.whiteLogo.maxWidth,
              height: client.whiteLogo.maxHeight,
              }} />
            {/* <StricLogo /> */}
          </WrapperLogo>
          <Title>
            Bem-vindo ao internet <br />
            banking{' '}
            {client.name && (
              <b>da {client.name}.</b>
            )}
          </Title>
          <Separator />
          {step === 1? (
            <>
            <Reference>
              Preencha os campos abaixo para recuperar sua senha
            </Reference>
            <WrapperForm onSubmit={handleSignIn}>
              <Input>
                <span>E-mail:</span>
                <input
                  placeholder="Digite seu E-mail" type="text"
                  onChange={(input: any) => setDocument(input.target.value)}
                  value={document}
                />
              </Input>

              {!!success && (
                <Error>
                  <div className="bg-green-50 border-l-4 border-green-400 p-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <CheckIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                      </div>
                      <div className="ml-3">
                        <p className="text-sm text-gray-700">
                          {success}
                        </p>
                      </div>
                    </div>
                  </div>
                </Error>
              )}

              {!!error && (
                <Error>
                  <div className="bg-red-50 border-l-4 border-red-400 p-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <ExclamationIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                      </div>
                      <div className="ml-3">
                        <p className="text-sm text-red-700">
                          {error}
                        </p>
                      </div>
                    </div>
                  </div>
                </Error>
              )}
              <ButtonSignIn type="submit">
                <Loading isLoading={loading} />
                Continuar
              </ButtonSignIn>
            </WrapperForm>

            </>

          ) : (
            <>
             <Reference>
              Preencha os campos abaixo para recuperar sua senha
            </Reference>

            <Reference>
              Configura o token que foi enviado no seu e-mail.
            </Reference>

            <WrapperForm onSubmit={handleReset}>
            <Input>
                <span>Token (Enviado por e-mail)</span>
                <input
                  placeholder="Token" type="text"
                  onChange={(input: any) => setConfirmationCode(input.target.value)}
                  value={confirmationCode}
                />
              </Input>

              <Input>
                <span>Informe sua nova senha</span>
                <input
                  placeholder="Nova senha" type="password"
                  onChange={(input: any) => setPassword(input.target.value)}
                  value={password}
                />
              </Input>

              <Input>
                <span>Informe sua nova senha</span>
                <input
                  placeholder="Confirme sua nova senha" type="password"
                  onChange={(input: any) => setPasswordConfirm(input.target.value)}
                  value={passwordConfirm}
                />
              </Input>

              {!!success && (
                <Error>
                  <div className="bg-green-50 border-l-4 border-green-400 p-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <CheckIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                      </div>
                      <div className="ml-3">
                        <p className="text-sm text-gray-700">
                          {success}
                        </p>
                      </div>
                    </div>
                  </div>
                </Error>
              )}

              {!!error && (
                <Error>
                  <div className="bg-red-50 border-l-4 border-red-400 p-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <ExclamationIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                      </div>
                      <div className="ml-3">
                        <p className="text-sm text-red-700">
                          {error}
                        </p>
                      </div>
                    </div>
                  </div>
                </Error>
              )}

              {!ok && (
                <ButtonSignIn type="submit">
                <Loading isLoading={loading} />
                Continuar
              </ButtonSignIn>
              )}
            </WrapperForm>

            </>

          )}
          <ForgetPassword href="/signin">Voltar para o login</ForgetPassword>
          <Line />
          {/* <NotHaveAnAccount to="/type-create-account">
            Não tem uma conta? Abrir conta {client.name}
          </NotHaveAnAccount> */}
        </Content>
      </WrapperContent>
    </Container>
  );
};
