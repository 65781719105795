import React from "react";

import {
  BadgeWrapper,
  Box,
  BoxWrapper,
  Container,
  ContentWrapper,
  Line,
  Subtitle,
  SubtitleWrapper,
  TitleWrapper,
} from "./styles";

import circleSeparator from "../../assets/circleseparator.svg";
import Card from "../LPcard";

const SectionFAQ: React.FC = () => {
  return (
    <>
      <Container id="FAQ">
        <BadgeWrapper>
          <img src={circleSeparator} alt={"Logo VIP, separador de seção"} />
        </BadgeWrapper>
        <ContentWrapper>
          <SubtitleWrapper>
            <Subtitle>FAQ</Subtitle>
            <Line />
          </SubtitleWrapper>
          <TitleWrapper>
            Dúvidas sobre a <strong>Stric?</strong>
          </TitleWrapper>
          <Box>
            <BoxWrapper>
              <Card
                number="01"
                text="A Stric é uma Conta Digital criada por empreendedores e para empreendedores, projetada para reduzir burocracias, eliminar taxas abusivas e descomplicar a administração das finanças de quem empreende!"
                title="O que é o banco Stric?"
              />
              <Card
                number="02"
                text="
                Somos uma instituição regulada pelo Banco Central (nosso “número do banco” é o 403) no formado de Sociedade de Crédito Direto – nessa modalidade, o seu dinheiro fica separado do patrimônio da Stric. Nós não movimentamos e emprestamos seu dinheiro, ele é realmente seu. Trata-se de configuração legal mais segura que existe! 
                "
                title="A conta corrente Stric é segura?"
              />
              <Card
                number="03"
                text="A Conta Stric é livre de tarifas de manutenção. Você também não paga por transferências e emissão de boletos no plano Padrão. Cobramos apenas pela emissão de boletos que são emitidos por meio da integração de sistemas individuais e/ou parceiros da Stric, como por exemplo ERPs."
                title="Quanto custa?"
              />
              <Card
                number="04"
                text="Optamos por não cobrar tarifas relacionadas a conta bancária. Os serviços que nos financiam são (e serão) outros, como cartão de crédito, integrações e funcionalidades avançadas dentro da conta. Além disso, não acreditamos que é justo cobrar tarifas abusivas pela manutenção de contas bancárias sem que nossos clientes desfrutem de um serviço especial. Cobrar pela emissão (ou compensação) de boletos pode inviabilizar o negócio de nossos clientes."
                title="Porque não existem taxas?"
              />
              <Card
                number="05"
                text="Não. Nossos boletos são grátis de verdade – você não paga nada para emitir (e nem para cancelar ou emitir novamente, se precisar) e os boletos também não possuem nenhuma taxa na compensação no valor. "
                title="O boleto tem taxa de compensação?"
              />
              <Card
                number="06"
                text="Sim, é necessário que sua empresa tenha CNPJ para ter o cadastro aprovado na Stric. Nosso app é destinado para autônomos, profissionais liberais e donos de pequenos e médios negócios, com empresas constituída"
                title="Preciso de um CNPJ?"
              />
              <Card
                number="07"
                text="Os boletos são emitidos dentro do aplicativo (dura menos de um minuto emitir um boleto), e já podem ser disponibilizados ao seu cliente imediatamente após a emissão."
                title="Quanto tempo leva para emitir boletos?"
              />
            </BoxWrapper>
          </Box>
        </ContentWrapper>
      </Container>
    </>
  );
};

export default SectionFAQ;
